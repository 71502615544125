import { Http } from "./Http";
import { getBaseEndpointUrl } from "./config";

export const signIn = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/signin", formData);
};

export const signInsocial = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/signin/google", formData);
};

export const checkUser = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/checkinfo", formData);
};

export const signUp = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/auth/signup", formData);
};
export const departmentList = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/settings/department_list");
};
export const collegeList = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/settings/college_list");
};
export const testList = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/question/test_list", Http.getAuthToken());
};
export const questionList = ({ department_id, question_group_id }) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
    "/question/question_list?department_id=" +
    department_id +
    "&question_group_id=" +
    question_group_id,
    Http.getAuthToken()
  );
};
export const answerAdd = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/question/answer_add",
    formData,
    Http.getAuthToken()
  );
};
export const answerList = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl + "/question/answer_list?user_id=" + id,
    Http.getAuthToken()
  );
};
export const studentList = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/question/student_list", Http.getAuthToken());
};

export const profile = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/user/my_account", Http.getAuthToken());
};

export const departmentUpdate = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/user/updatedepartment",
    formData,
    Http.getAuthToken()
  );
};

export const completedList = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/question/completed_list", Http.getAuthToken());
};

export const downloadReport = (student, college) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
    "/user/download_report?student=" +
    student +
    "&college=" +
    college,
    Http.getAuthToken()
  );
};

export const hiring_partner_list = (query, limit, page) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/hiring_partner/?query=" + query + "&limit=" + limit + "&page=" + page, Http.getAuthToken());
};

export const add_hiring_partner = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/hiring_partner/add", formData, Http.getAuthToken());
}

export const get_hiring_partner = (hiringPartnerId) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/hiring_partner/byId", hiringPartnerId, Http.getAuthToken());
}

export const edit_hiring_partner = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(baseUrl + "/hiring_partner/update", formData, Http.getAuthToken());
}

export const get_student_data = (formatData) => {
  const baseUrl = getBaseEndpointUrl();  
  return Http.get(baseUrl + "/students/student_details", Http.getAuthToken());
}

export const my_account_data = () => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/user/my_account", Http.getAuthToken());
}


export const college_list = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/settings/college_list");
};