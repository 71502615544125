import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { LoginSocialGoogle, LoginSocialTwitter } from "reactjs-social-login";
import { toast } from "react-toastify";
import {
    signUpScheduler,
    signUpSelector,
    clearErrormsg,
} from "../../store/reducer/SignUpReducer";
import { API_STATUS } from "../../utils/Constants";
import { Header } from "../layout/Header";
import Footer from "../layout/Footer";
import b1 from "../../webassets/images/banner/banner-one-shape-1.png";
import b2 from "../../webassets/images/banner/banner-one-shape-2.png";
import b3 from "../../webassets/images/banner/banner-one-shape-3.png";
import {
    testLists,
    questionLists,
    placementSelector,
    departmentLists,
    completedLists,
    departmentUpdates,
} from "../../store/reducer/PlacementReducer";
import { hiring_partnerLists, hiringpartnerSelector } from "../../store/reducer/HiringPartnerReducer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "../../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../../utils/tableRows";

import {
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    CardHeader,
    Collapse,
    Container,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from "reactstrap";

const HiringPartner = () => {
    const recaptchaRef = React.createRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { hiringPartnerDatas, hiringPartnerLoading } = useSelector(hiringpartnerSelector);
    const { testData, testLoading, departmentData, errorMessage, departupdateLoading } =
        useSelector(placementSelector);
    const [departmentID, setDepartmentID] = useState("");

    let [sortBy, setSortBy] = useState("name");
    let [activesortBy, setActiveSortBy] = useState("name");
    let [page, setPage] = useState(0);
    let [rowsPerPage, setRowsPerPage] = useState(10);
    let [order, setOrder] = useState("asc");



    useEffect(() => {
        if (!localStorage.getItem("authToken")) {
            navigate("/");
        }
        dispatch(testLists({}));
        dispatch(departmentLists({}));
    }, []);

    useEffect(() => {
        let query = "";
        let limit = 0;
        let page = 0;
        dispatch(hiring_partnerLists({ query, limit, page }));
    }, []);

    useEffect(() => {
        console.log(hiringPartnerDatas, "studentDatas");
    }, [hiringPartnerDatas]);

    const handleChangeRowsPerPage = (event) => {
        page = 0;
        rowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        page = newPage;
        setPage(newPage);
    };


    const toEdit = (id) => {
        console.log(id, "HPID");
    }

    useEffect(() => {
        if (departupdateLoading === API_STATUS.FULFILLED) {
            navigate("/question");
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message) toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                });
                toast.error(data);
            }
            dispatch(clearErrormsg());
        }
    }, [departupdateLoading, errorMessage]);

    return (
        <>
            <Header />
            <div
                class="page-banner-area events-pages event-details"
                style={{ paddingBottom: "50px", paddingTop: "50px" }}
            >
                <div class="container-fluid">
                    <div class="single-page-banner-content">
                        <h1>Hiring Partners</h1>
                        <ul>
                            <li>Welcome to Hiring Partners List</li>
                        </ul>
                    </div>
                </div>
                <div class="page-banner-shape-1">
                    <img src={b1} alt="images" />
                </div>
                <div class="page-banner-shape-2">
                    <img src={b2} alt="images" />
                </div>
                <div class="page-banner-shape-3">
                    <img src={b3} alt="images" />
                </div>
            </div>

            <section class="pad_100">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2 class="sub_heading">Hiring Partners List</h2>
                            <div className="table-responsive react-table">
                                <div style={{ textAlign: "right", margin: "auto" }}>
                                <Link  to={`/add_hiring_partners`}>
                                    <button
                                        type="button"
                                        className="waves-effect waves-light btn btn-success mb-2 text-right"
                                      
                                    >
                                        Add Hiring Partner
                                    </button>
                                    </Link>
                                </div>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} stickyHeader>
                                        <TableHead className="table-light table-nowrap">
                                            <TableRow>
                                                <StyledTableCell align="">Organization Name</StyledTableCell>
                                                <StyledTableCell align="">Email Id</StyledTableCell>
                                                <StyledTableCell align="">
                                                    Phone NUmber
                                                </StyledTableCell>
                                                <StyledTableCell align="">Action</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {hiringPartnerDatas &&
                                                hiringPartnerDatas.map((row) => (
                                                    <StyledTableRow>
                                                        <StyledTableCell align="">
                                                            {row?.name}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.email}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="">
                                                            {row?.phone_no}
                                                        </StyledTableCell>
                                                        <StyledTableCell align="" className="button-items">
                                                        <Link  to={`/edit_hiring_partners/`+row?.id}                                                  
                                                                title="Edit"
                                                                href="javascript:void(0);"
                                                                class="btn btn-style-one btn-primary"                                                              
                                                           > Edit                                                            
                                                            </Link>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[10, 25, 50]}
                                                    colSpan={8}
                                                    count={0}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: {
                                                            "aria-label": "rows per page",
                                                        },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
              
            </section>
            <Footer />
        </>
    );
};

export default HiringPartner;
