import React, {
    useEffect,
    useState,
    useCallback,
    useRef,
    ClipboardEvent,
} from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { edithiringPartnerSchema } from "../../utils/Validation";
import { LoginSocialGoogle, LoginSocialTwitter } from "reactjs-social-login";
import { toast } from "react-toastify";
import { API_STATUS } from "../../utils/Constants";
import { Header } from "../layout/Header";
import Footer from "../layout/Footer";
import b1 from "../../webassets/images/banner/banner-one-shape-1.png";
import b2 from "../../webassets/images/banner/banner-one-shape-2.png";
import b3 from "../../webassets/images/banner/banner-one-shape-3.png";
import { hiring_partnerLists, addHiringPartner, EditHiringPartnerData, getHiringPartner, hiringpartnerSelector, clearData } from "../../store/reducer/HiringPartnerReducer";

import "../../assets/css/style.css";
import "../../assets/css/homepage.css";
import "../../assets/css/responsive.css";

const EditHiringPartner = () => {
    const recaptchaRef = React.createRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { id } = useParams();

    const { hiringPartnerDatas, hiringPartnerLoading, hiringPartnerEditData, hiringPartnerEditLoading,errorMessage } = useSelector(hiringpartnerSelector);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(edithiringPartnerSchema),
    });


    const onSubmitHandler = (formData) => {
        formData.id = Number(id);

		delete formData.college;
		delete formData.course;
		delete formData.department;
		delete formData.dob;
		delete formData.password;
		delete formData.updated_at;
		delete formData.created_at;
		delete formData.role_id;
		delete formData.roll_no;
		delete formData.specialization;
		delete formData.year;
		delete formData.status;
		delete formData.trash;
        console.log({ formData }, "FORMDSATAWS");

        dispatch(EditHiringPartnerData({ formData }));
    };

    useEffect(() => {
        let hiringPartnerId = {};
        hiringPartnerId.id = id;
        dispatch(getHiringPartner({ hiringPartnerId }));
    }, [])

    useEffect(() => {
        reset(hiringPartnerEditData)
    }, [hiringPartnerEditData])


    useEffect(() => {
        console.log(hiringPartnerEditLoading, "hiringPartnerEditLoading");
        if (hiringPartnerEditLoading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Hiring Partner Updated Successfully!");
            reset();
            dispatch(clearData());
            navigate('/hiring_partners');
        }
        if (errorMessage.length > 0) {
            toast.dismiss();
            console.log(errorMessage,"TESTS");
            if (errorMessage?.message) toast.error(errorMessage?.message);
            else if (errorMessage?.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                });
                toast.error(data);
            }
            dispatch(clearData());
        }
    }, [hiringPartnerEditLoading, errorMessage]);

    return (
        <>
            <Header />
            <div
                class="page-banner-area events-pages event-details"
                style={{ paddingBottom: "50px", paddingTop: "50px" }}
            >
                <div class="container-fluid">
                    <div class="single-page-banner-content">
                        <h1>Hiring Partners</h1>
                        <ul>
                            <li>Welcome to Edit Hiring Partner</li>
                        </ul>
                    </div>
                </div>
                <div class="page-banner-shape-1">
                    <img src={b1} alt="images" />
                </div>
                <div class="page-banner-shape-2">
                    <img src={b2} alt="images" />
                </div>
                <div class="page-banner-shape-3">
                    <img src={b3} alt="images" />
                </div>
            </div>

            <section class="pad_100">
                <div class="container">
                    <div class="row">
                        <div class="section-title left-title">
                            {/* <h2>
                  Placement Officer Survey: Understanding IT Industry Expectations
                  and Student Mindset
                </h2>
                <p>
                  Thank you for participating in this survey. Your insights are
                  invaluable in shaping the future of placements at our college.
                  The information gathered will help us enhance our understanding
                  of the IT industry's expectations and the factors influencing
                  students success in job interviews.
                </p> */}
                        </div>

                        <div class="col-md-12">
                            <div style={{ textAlign: "right", margin: "auto" }}>
                                <Link to={`/hiring_partners`}>
                                    <button
                                        type="button"
                                        className="waves-effect waves-light btn btn-success mb-2 text-right"
                                    >
                                        Hiring Partner List
                                    </button>
                                </Link>
                            </div>
                            <div class="form">

                                <h2 class="sub_heading">Edit Hiring Partner</h2>
                                <form onSubmit={handleSubmit(onSubmitHandler)}>
                                    <div class="form_group">
                                        <div className="row">

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 pl-1" style={{ paddingRight: "15px" }}>
                                                <div className="form-group">
                                                    <label htmlFor="">Organization Name</label>
                                                    <input
                                                        type="text"
                                                        {...register("name")}
                                                        // defaultValue={hiringPartnerEditData?.name}
                                                    />
                                                    {errors?.name?.message && (
                                                        <p className="error">{errors.name.message}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style={{ paddingRight: "15px" }}>
                                                <div className="form-group">
                                                    <label htmlFor="">Phone</label>
                                                    <input
                                                        type="text"
                                                        {...register("phone_no")}
                                                      
                                                    />
                                                    {errors?.phone_no?.message && (
                                                        <p className="error">{errors.phone_no.message}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Email Address</label>
                                                    <input
                                                        type="text"
                                                        {...register("email")}
                                                       
                                                    />
                                                    {errors?.email?.message && (
                                                        <p className="error">{errors.email.message}</p>
                                                    )}
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Address</label>
                                                    <textarea cols="30" rows="10" 
                                                    // defaultValue={hiringPartnerEditData?.address}
                                                        {...register("address")}>
                                                    </textarea>                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                            </div>
                                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                <button
                                                type="submit"
                                                    name="question_submit"
                                                    class="submit_btn Edit_submit"
                                                >
                                                    <span class="btnText">Submit</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default EditHiringPartner;
