import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { signInValidationSchema } from "../utils/Validation";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import logo2 from "../webassets/images/Asset 7.png";

import "../assets/css/style.css";
import "../assets/css/homepage.css";
import {
  signInScheduler,
  signInSelector,
  clearData,
  signInSocial,
} from "../store/reducer/SignInReducer";
import { API_STATUS } from "../utils/Constants";
import "../assets/css/responsive.css";
const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signInData, signInLoading,errorMessage } = useSelector(signInSelector);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(signInValidationSchema),
  });
  const REDIRECT_URI = "http://localhost:3000/signup";
  const onSubmitHandler = (formData) => {
    console.log({ formData },"FORMDASDFD");
    dispatch(signInScheduler({ formData }));
    // reset();
  };

  const [captcha, setCaptcha] = useState({
    callback: "not fired",
    value: "[empty]",
    load: false,
    expired: "false",
    recaptchaLoaded: false,
  });
  const recaptchaRef = React.createRef();


  const handleChange = (value) => {
    setCaptcha({ value });
    if (value === null) setCaptcha({ expired: "true" });
  };

  const asyncScriptOnLoad = () => {
    setCaptcha({ callback: "called!", recaptchaLoaded: true });
  };

  useEffect(() => {
    console.log(signInLoading,"signInLoading");
    if (signInLoading === API_STATUS.FULFILLED) {
      toast.dismiss();
      toast.success("Logged in Successfully!");
      reset();
      dispatch(clearData())
      navigate("/inter");
    }
    if (signInLoading === API_STATUS.REJECTED) {
      toast.dismiss();
      console.log(errorMessage.error,"ERRORS");
      toast.error(errorMessage.error);
    }
  }, [signInLoading,errorMessage]);

  return (
    <>
      <section>
        <div className="flex sign_page">
          <div className="col-md-12 col-lg-6 col-sm-12 col-xs-12">
            <div className="sign_img flex align-center">
              <div className="sign_img_cnt">
                <img src={logo2} class="logo-dark" alt="images" />
                <h1>Create a Workplace Culture that Inspires and Innovates.</h1>
                {/* <p>
                  Register as <Link to="/add_hiring_partners">Hiring Partner?</Link>
                </p> */}
                {/* <p>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur.
                </p> */}
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-sm-12 col-xs-12">
            <div className="sign_content flex">
              <div className="create_account">
                <p>
                  Don't have an account? <Link to="/signup">Sign up</Link>
                </p>
              </div>
              <div className="sign_info">
                <h3>Sign in</h3>
                <div className="sign_form">
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="form-group">
                      <label htmlFor="email">
                        Email address
                      </label>
                      <input
                        id="email"
                        type="text"
                        name="email"
                        {...register("email")}
                      />
                      {errors?.email?.message && (
                        <p className="error">{errors.email.message}</p>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Your Password</label>
                      <input
                        type="password"
                        id="password"
                        name="register"
                        {...register("password")}
                      />
                      {errors?.password?.message && (
                        <p className="error">{errors.password.message}</p>
                      )}
                      {/* <a href="#">Forgot Password?</a> */}
                      <ReCAPTCHA
                        style={{ display: "inline-block" }}
                        theme="dark"
                        size="invisible"
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_G_CAPTCHA || ""}
                        onChange={handleChange}
                        asyncScriptOnLoad={asyncScriptOnLoad}
                      />
                    </div>
                    <input
                      type="submit"
                      name="signin"
                      value="Sign in"
                      className="sign_button"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignIn;
