import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import { get_student_data } from "../../services/api";

const namespace = "Student";

const initialState = {
  loading: "initial",
  errorMessage: "",
  StudentDatas: null,
  StudentLoading: "initial",
  StudentListLoading: "initial",
  count: 0,
};

export const getStudent = createAsyncThunk(
  `${namespace}/getStudent`,
  async ({formatData}, { rejectWithValue, dispatch }) => {
    try {      
      const { data } = await get_student_data({formatData});
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const StudentSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearStudentData: () => {
      return initialState;
    },
    clearData: (state) => {
      state.StudentLoading = "initial";
      state.StudentListLoading = "initial";
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getStudent.pending, (state) => {
      state.StudentLoading = API_STATUS.PENDING;
    });
    builder.addCase(getStudent.fulfilled, (state, { payload }) => {
      state.StudentLoading = API_STATUS.FULFILLED;
      state.StudentDatas = payload?.data;
      console.log(payload, "sdPAy");
    });
    builder.addCase(getStudent.rejected, (state, action) => {
      state.StudentLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const { clearStudentData, clearData } = StudentSlice.actions;

export const StudentSelector = (state) => state.Student;

export default StudentSlice.reducer;
