import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { signUpValidationSchema } from "../utils/Validation";
import ReCAPTCHA from "react-google-recaptcha";
import { LoginSocialGoogle, LoginSocialTwitter } from "reactjs-social-login";
import { toast } from "react-toastify";
import {
  signUpScheduler,
  signUpSelector,
  clearErrormsg,
} from "../store/reducer/SignUpReducer";
import { API_STATUS } from "../utils/Constants";
import { InnerHeader } from "./layout/InnerHeader";
import Footer from "./layout/Footer";
import PlainFooter from "./layout/PlainFooter";
import b1 from "../webassets/images/banner/banner-one-shape-1.png";
import b2 from "../webassets/images/banner/banner-one-shape-2.png";
import b3 from "../webassets/images/banner/banner-one-shape-3.png";
import {
  testLists,
  questionLists,
  placementSelector,
  departmentLists,
  completedLists,
  departmentUpdates,
} from "../store/reducer/PlacementReducer";
import { testList } from "../services/api";
const InterviewTest = () => {
  const recaptchaRef = React.createRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signUpData, signUpLoading } = useSelector(signUpSelector);
  const {
    testData,
    testLoading,
    departmentData,
    errorMessage,
    departupdateLoading,
  } = useSelector(placementSelector);
  const [departmentID, setDepartmentID] = useState("");
  useEffect(() => {
        dispatch(departmentLists({}));
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(signUpValidationSchema),
  });

  const DELAY = 1500;
  const [captcha, setCaptcha] = useState({
    callback: "not fired",
    value: "[empty]",
    load: false,
    expired: "false",
    recaptchaLoaded: false,
  });
  // const validation = z.object({
  //   username: z.email(),
  //   password: z.any(),
  // });
  // const validate = (field) => {
  //   validation.parse({ username: field });
  // };

  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/");
    }
  }, []);

  const handleChange = (value) => {
    console.log("onChange prop - Captcha value:", value);
    setCaptcha({ value });
    if (value === null) setCaptcha({ expired: "true" });
  };

  const asyncScriptOnLoad = () => {
    setCaptcha({ callback: "called!", recaptchaLoaded: true });
    console.log("scriptLoad - reCaptcha Ref-", recaptchaRef);
  };
  const startTest = () => {
    if (departmentID !== "") {
      localStorage.setItem("departmentID", departmentID);
      localStorage.setItem("question_group_id", 1);
      let formData = { department: departmentID };
      dispatch(departmentUpdates({ formData }));
    }
  };

  useEffect(() => {
    if (departupdateLoading === API_STATUS.FULFILLED) {
      navigate("/question");
    }
    if (errorMessage) {
      toast.dismiss();
      if (errorMessage.message) toast.error(errorMessage.message);
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        toast.error(data);
      }
      dispatch(clearErrormsg());
    }
  }, [departupdateLoading, errorMessage]);

  return (
    <>
      <div class="questions_page">
        <InnerHeader page="0" />
        <div
          class="page-banner-area events-pages event-details"
          style={{ paddingBottom: "10px", paddingTop: "10px" }}
        >
          <div class="container-fluid">
            <div class="single-page-banner-content">
              <h1>Pre-Placement Drive</h1>
              {/* <ul>
                <li>Welcome to Pre-Placement Drive</li>
              </ul> */}
            </div>
          </div>
        </div>

        <section class="pad_100">
          <div class="container">
            <div class="row">
              <div class="section-title left-title">
                {/* <h2>
                Placement Officer Survey: Understanding IT Industry Expectations
                and Student Mindset
              </h2>
              <p>
                Thank you for participating in this survey. Your insights are
                invaluable in shaping the future of placements at our college.
                The information gathered will help us enhance our understanding
                of the IT industry's expectations and the factors influencing
                students success in job interviews.
              </p> */}
              </div>

              <div class="col-md-12">
                <div class="form">
                  <h2 class="sub_heading">
                    Select the profile of your choice and proceed with the test
                  </h2>
                  <div class="form_group">
                    <label>
                      {/* <b>Choose your prefered profile and proceed with test</b> */}
                    </label>
                    <div class="select">
                      <select
                        name="department"
                        class="optionType"
                        id="opt"
                        onChange={(e) => {
                          setDepartmentID(e.target.value);
                        }}
                      >
                        <option value=""> Choose Profile</option>
                        {departmentData &&
                          departmentData.map((fields) => {
                            return (
                              <option value={fields.id}>{fields.name}</option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                  <button
                    name="question_submit"
                    class="submit_btn"
                    onClick={(e) => {
                      startTest();
                    }}
                  >
                    <span class="btnText">Start Test</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <Footer /> */}
        <PlainFooter />
      </div>
    </>
  );
};

export default InterviewTest;
