import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { signUpValidationSchema } from "../utils/Validation";
import ReCAPTCHA from "react-google-recaptcha";
import { LoginSocialGoogle, LoginSocialTwitter } from "reactjs-social-login";
import { toast } from "react-toastify";
import {
  signUpScheduler,
  signUpSelector,
  clearErrormsg,
} from "../store/reducer/SignUpReducer";
import { API_STATUS } from "../utils/Constants";
import { Header } from "./layout/Header";
import Footer from "./layout/Footer";
import PlainFooter from "./layout/PlainFooter";
import b1 from "../webassets/images/banner/banner-one-shape-1.png";
import b2 from "../webassets/images/banner/banner-one-shape-2.png";
import b3 from "../webassets/images/banner/banner-one-shape-3.png";
import {
  testLists,
  questionLists,
  placementSelector,
  departmentLists,
  answerAdds
} from "../store/reducer/PlacementReducer";
import { getStudent, StudentSelector } from "../store/reducer/StudentReducer";
import { InnerHeader } from "./layout/InnerHeader";
const ThankYou = () => {
  const recaptchaRef = React.createRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signUpData, signUpLoading } = useSelector(signUpSelector);
  const { questionData, answerLoading, errorMessage } = useSelector(placementSelector);
  const { StudentDatas } = useSelector(StudentSelector);
  let [answers, setAnswers] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(signUpValidationSchema),
  });
  const REDIRECT_URI = "http://localhost:3000/account/login";

  // useEffect(() => {
  //   dispatch(getStudent({}));
  // }, []);

  return (
    <div className="questions_page">
      <InnerHeader  page="0"/>
        <div
        class="page-banner-area events-pages event-details"
        style={{ paddingBottom: "10px", paddingTop: "10px" }}
      >
        <div class="container-fluid">
          <div class="single-page-banner-content">
            <h1>Pre-Placement Drive</h1>
          </div>
        </div>
      </div>

      <section class="pad_100">
        <div class="container">
          <div class="row">
            <div class="section-title left-title">
              {/* <h2>
                Placement Officer Survey: Understanding IT Industry Expectations
                and Student Mindset
              </h2>
              <p>
                Thank you for participating in this survey. Your insights are
                invaluable in shaping the future of placements at our college.
                The information gathered will help us enhance our understanding
                of the IT industry's expectations and the factors influencing
                students success in job interviews.
              </p> */}
            </div>

            < div class="conference-planning-area pad_100">
              <div class="container">
                <div class="section-title">
                  <h2>Thank you! Our AI will analyse your answers and we will get back to you.</h2>
                </div>
                {/* <div className="bg-light col-md-12 ">
                  <div className="row text-dark mt-2">
                    <div className="col-md-6 d-flex justify-content-around">Ddepartment: {StudentDatas?.departments?.name}</div>
                    <div className="col-md-6 d-flex justify-content-around">Overall Score: {StudentDatas?.primarlimary_test_percentage}/100</div>
                  </div>
                  {StudentDatas?.primarlimary_test_status == '1' ?
                    <div className="text-center">
                      <button className="btn btn-primary  mb-2">Go to Dashboard</button>
                    </div>
                    :
                    <div className="text-center">
                      <button className="btn btn-primary  mb-2">To Continue Subscribe</button>
                    </div>
                  }
                </div> */}
              </div>
            </div>

          </div>
        </div>
      </section >
      {/* <Footer /> */}
      <PlainFooter />
    </div>
  );
};

export default ThankYou;
