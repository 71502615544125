import React, {
    useEffect,
    useState,
    useCallback,
    useRef,
    ClipboardEvent,
} from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { hiringPartnerSchema } from "../../utils/Validation";
import { LoginSocialGoogle, LoginSocialTwitter } from "reactjs-social-login";
import { toast } from "react-toastify";
import { API_STATUS } from "../../utils/Constants";
import { Header } from "../layout/Header";
import Footer from "../layout/Footer";
import b1 from "../../webassets/images/banner/banner-one-shape-1.png";
import b2 from "../../webassets/images/banner/banner-one-shape-2.png";
import b3 from "../../webassets/images/banner/banner-one-shape-3.png";
import { hiring_partnerLists, addHiringPartner, hiringpartnerSelector, clearData } from "../../store/reducer/HiringPartnerReducer";
import logo2 from "../../webassets/images/Asset 7.png";
import "../../assets/css/homepage.css";
import "../../assets/css/responsive.css";

const AddHiringPartner = () => {
    const recaptchaRef = React.createRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { hiringPartnerDatas, hiringPartnerLoading, errorMessage } = useSelector(hiringpartnerSelector);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(hiringPartnerSchema),
    });


    const onSubmitHandler = (formData) => {
        console.log({ formData }, "FORMDSATAWS");
        dispatch(addHiringPartner({ formData }));
    };

    useEffect(() => {
        console.log(hiringPartnerLoading, "hiringPartnerLoading");
        if (hiringPartnerLoading === API_STATUS.FULFILLED) {
            toast.dismiss();
            toast.success("Thanks for registering! Our admin will contact you shortly for next steps. Stay tuned!");
            reset();
            dispatch(clearData());
        }
        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message) toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                });
                toast.error(data);
            }
            dispatch(clearData());
        }
    }, [hiringPartnerLoading, errorMessage]);

    return (
        <>
            <section>
                <div className="flex sign_page">
                    <div className="col-md-12 col-lg-6 col-sm-12 col-xs-12 p-0">
                        <div className="sign_img signup flex align-center">
                            <div className="sign_img_cnt">
                                <img src={logo2} class="logo-dark" alt="images" />
                                <h1>Create a Workplace Culture that Inspires and Innovates.</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-6 col-sm-12 col-xs-12 p-0">
                        <div className="sign_content flex align-center">
                            <div className="sign_info signup">
                                <h3>Create a Hiring Partner Account</h3>
                                {/* <p>
                                    Already have an account?{" "}
                                    <Link to="/" style={{ color: "#323232!important" }}>
                                        Sign in
                                    </Link>
                                </p> */}

                                <div className="sign_form">
                                    <form onSubmit={handleSubmit(onSubmitHandler)}>
                                        <div class="form_group">
                                            <div className="row">

                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 pl-1" style={{ paddingRight: "15px" }}>
                                                    <div className="form-group">
                                                        <label htmlFor="">Organization Name</label>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            {...register("name")}
                                                        />
                                                        {errors?.name?.message && (
                                                            <p className="error">{errors.name.message}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style={{ paddingRight: "15px" }}>
                                                    <div className="form-group">
                                                        <label htmlFor="">Phone</label>
                                                        <input
                                                            type="text"
                                                            name="phone_no"
                                                            {...register("phone_no")}
                                                        />
                                                        {errors?.phone_no?.message && (
                                                            <p className="error">{errors.phone_no.message}</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                    <div className="form-group">
                                                        <label htmlFor="">Email Address</label>
                                                        <input
                                                            type="text"
                                                            name="email"
                                                            {...register("email")}
                                                        />
                                                        {errors?.email?.message && (
                                                            <p className="error">{errors.email.message}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style={{ paddingRight: "15px" }}>
                                                    <div className="form-group">
                                                        <label htmlFor="">Create Password</label>
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            {...register("password")}
                                                        />
                                                        <span>
                                                            Use Upper case, Lower case, Numbers & Symbols.Password length should be minimum 8 characters.
                                                        </span>
                                                        {errors?.password?.message && (
                                                            <p className="error">{errors.password.message}</p>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                 {/* <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                                    <div className="form-group">
                                                        <label htmlFor="" style={{color:"black"}}>Address</label>
                                                        <textarea name="address" id="" cols="30" rows="10"  {...register("address")}>
                                                        </textarea>
                                                        {errors?.address?.message && (
                                                            <p className="error">
                                                                {errors.address.message}
                                                            </p>
                                                        )}
                                                    </div>
                                                     <div className="form-group">
                                                        <label htmlFor="">Address</label>
                                                        <input
                                                            type="address"
                                                            name="address"
                                                            {...register("address")}
                                                        />
                                                        {errors?.address?.message && (
                                                            <p className="error">{errors.address.message}</p>
                                                        )}
                                                    </div> 
                                                </div> */}
                                                <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-end mt-4">
                                                    <button
                                                        name="question_submit"
                                                        class="submit_btn"
                                                    >
                                                        <span class="btnText">Submit</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AddHiringPartner;
