import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import {
  departmentList,
  collegeList,
  testList,
  questionList,
  answerAdd,
  answerList,
  studentList,
  departmentUpdate,
  completedList,
  downloadReport
} from "../../services/api";

const namespace = "placement";

const initialState = {
  loading: "initial",
  errorMessage: "",
  collegeData: null,
  departmentData: null,
  questionData: null,
  departmentLoading: "initial",
  collegeLoading: "initial",
  testLoading: "initial",
  questionLoading: "initial",
  answerLoading: "initial",
  studentsLoading: "initial",
  departupdateLoading: "initial",
  completedData:null,
  completedListLoading:"initial",
  testData: null,
  reportsLoading:"initial",
  reportsData: null,
  answerDatas: null,
  studentDatas: null,
  count: 0,
};

export const departmentLists = createAsyncThunk(
  `${namespace}/departmentLists`,
  async ({ }, { rejectWithValue, dispatch }) => {
    try {
      console.log();
      const { data } = await departmentList();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const collegeLists = createAsyncThunk(
  `${namespace}/collegeLists`,
  async ({ }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await collegeList();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const testLists = createAsyncThunk(
  `${namespace}/testLists`,
  async ({ }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await testList();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const completedLists = createAsyncThunk(
  `${namespace}/completedLists`,
  async ({ }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await completedList();
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const downloadReports = createAsyncThunk(
  `${namespace}/downloadReports`,
  async ({student,college}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await downloadReport(student,college);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);


export const questionLists = createAsyncThunk(
  `${namespace}/questionLists`,
  async (
    { department_id, question_group_id },
    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log(department_id,"DEPARTIDS");
      const { data } = await questionList({ department_id, question_group_id });
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const answerAdds = createAsyncThunk(
  `${namespace}/answerAdds`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData")
      const { data } = await answerAdd(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const departmentUpdates = createAsyncThunk(
  `${namespace}/departmentUpdates`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData")
      const { data } = await departmentUpdate(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);


export const answerLists = createAsyncThunk(
  `${namespace}/answerLists`,
  async ({ id }, { rejectWithValue, dispatch }) => {
    try {
      console.log(id, "formData")
      const { data } = await answerList(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const studentLists = createAsyncThunk(
  `${namespace}/studentLists`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData")
      const { data } = await studentList(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const placementSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearSignUpData: () => {
      return initialState;
    },
    clearErrormsg: (state) => {
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(departmentLists.pending, (state) => {
      state.departmentLoading = API_STATUS.PENDING;
    });
    builder.addCase(departmentLists.fulfilled, (state, { payload }) => {
      state.departmentLoading = API_STATUS.FULFILLED;
      state.departmentData = payload?.data;
    });
    builder.addCase(departmentLists.rejected, (state, action) => {
      state.departmentLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(collegeLists.pending, (state) => {
      state.collegeLoading = API_STATUS.PENDING;
    });
    builder.addCase(collegeLists.fulfilled, (state, { payload }) => {
      state.collegeLoading = API_STATUS.FULFILLED;
      state.collegeData = payload;
    });
    builder.addCase(collegeLists.rejected, (state, action) => {
      state.collegeLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(testLists.pending, (state) => {
      state.testLoading = API_STATUS.PENDING;
    });
    builder.addCase(testLists.fulfilled, (state, { payload }) => {
      state.testLoading = API_STATUS.FULFILLED;
      state.testData = payload;
    });
    builder.addCase(testLists.rejected, (state, action) => {
      state.testLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(questionLists.pending, (state) => {
      state.questionLoading = API_STATUS.PENDING;
    });
    builder.addCase(questionLists.fulfilled, (state, { payload }) => {
      state.questionLoading = API_STATUS.FULFILLED;
      state.questionData = payload?.data;
    });
    builder.addCase(questionLists.rejected, (state, action) => {
      state.questionLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(answerAdds.pending, (state) => {
      state.answerLoading = API_STATUS.PENDING;
    });
    builder.addCase(answerAdds.fulfilled, (state, { payload }) => {
      state.answerLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(answerAdds.rejected, (state, action) => {
      state.answerLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(answerLists.pending, (state) => {
      state.answerLoading = API_STATUS.PENDING;
    });
    builder.addCase(answerLists.fulfilled, (state, action) => {
      state.answerLoading = API_STATUS.FULFILLED;
      state.answerDatas = action?.payload?.data;
    });
    builder.addCase(answerLists.rejected, (state, action) => {
      state.answerLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(studentLists.pending, (state) => {
      state.studentsLoading = API_STATUS.PENDING;
    });
    builder.addCase(studentLists.fulfilled, (state, action) => {
      state.studentsLoading = API_STATUS.FULFILLED;
      state.studentDatas = action?.payload?.data;
    });
    builder.addCase(studentLists.rejected, (state, action) => {
      state.studentsLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(departmentUpdates.pending, (state) => {
      state.departupdateLoading = API_STATUS.PENDING;
    });
    builder.addCase(departmentUpdates.fulfilled, (state, action) => {
      state.departupdateLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(departmentUpdates.rejected, (state, action) => {
      state.departupdateLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(completedLists.pending, (state) => {
      state.completedListLoading = API_STATUS.PENDING;
    });
    builder.addCase(completedLists.fulfilled, (state, { payload }) => {
      state.completedListLoading = API_STATUS.FULFILLED;
      state.completedData = payload;
    });
    builder.addCase(completedLists.rejected, (state, action) => {
      state.completedListLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(downloadReports.pending, (state) => {
      state.reportsLoading = API_STATUS.PENDING;
    });
    builder.addCase(downloadReports.fulfilled, (state, { payload }) => {
      state.reportsLoading = API_STATUS.FULFILLED;
      console.log(payload,"payloda")
      state.reportsData = payload?.data;
    });
    builder.addCase(downloadReports.rejected, (state, action) => {
      state.reportsLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });

    
  },
});

export const { clearSignUpData, clearErrormsg } = placementSlice.actions;

export const placementSelector = (state) => state.placement;

export default placementSlice.reducer;
