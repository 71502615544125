import { assetURL } from "../../services/config";
import logo1 from "../../webassets/images/Asset 8.png";
import logo2 from "../../webassets/images/Asset 7.png";
import { useNavigate, Link, useLocation, useSearchParams } from "react-router-dom";

const Footer = () => {
  return (
    <div class="footer-area pt-100 jarallax" data-jarallax='{"speed": 0.3}'>
      <div class="container">
        <div class="row">
          <div
            class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12"
            data-cue="slideInUp"
          >
            <div class="footer-widget">
              <Link to={`/question`}>
                <img src={logo2} alt="images" />
              </Link>
              <p>
                There are many variations of ipsum available majority have words
                which don't look.
              </p>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12"
            data-cue="slideInUp"
          >
            <div class="footer-widget footer-widget-link">
              <h2>Useful Links</h2>
              <ul class="footer-widget-list">
                <li>
                  <Link to={`/question`}>Home</Link>
                </li>
                <li>
                  <a href={null}>About Us</a>
                </li>
                <li>
                  <a href={null}>Partners</a>
                </li>
                <li>
                  <a href={null}>Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12"
            data-cue="slideInUp"
          >
            <div class="footer-widget footer-widget-link2">
              <h2>Find Conference</h2>
              <ul class="footer-widget-list">
                <li>
                  <a href="#">Business Conference</a>
                </li>
                <li>
                  <a href="#">Design Conference</a>
                </li>
                <li>
                  <a href="#">Web Design Seminar</a>
                </li>
                <li>
                  <a href="#">Global Conference</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12"
            data-cue="slideInUp"
          >
            <div class="footer-subscribe">
              <p>
                <i class="bx bx-map footer-loc" style={{ color: "#ffffff" }}></i>
                <span>
                  No 22, Sivaram Nagar, <br />
                  2nd Cross Street East, <br />
                  Sungam, Ramanathapuram, <br />
                  Coimbatore - 641045.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="copyright-content">
          <p>
            © <b>Culture Catalyst</b> is Proudly Owned by
            <a href="https://digital-garage.in/" target="_blank">
              Digital Garage
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
