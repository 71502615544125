import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { signUpValidationSchema } from "../utils/Validation";
import ReCAPTCHA from "react-google-recaptcha";
import { LoginSocialGoogle, LoginSocialTwitter } from "reactjs-social-login";
import { toast } from "react-toastify";
import logo2 from "../webassets/images/Asset 7.png";
import {
  signUpScheduler,
  signUpSelector,
  clearErrormsg,
  collegeList
} from "../store/reducer/SignUpReducer";
import { API_STATUS } from "../utils/Constants";

import "../assets/css/style.css";
import "../assets/css/homepage.css";
import "../assets/css/responsive.css";
const SignUp = () => {
  const recaptchaRef = React.createRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signUpData, signUpLoading, errorMessage, collegeData } =
    useSelector(signUpSelector);

  useEffect(() => {
    let formData = {};
    formData.query = "";
    formData.limit = 0;
    formData.page = 0;
    dispatch(collegeList({ formData }));
  }, [])
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(signUpValidationSchema),
  });
  const REDIRECT_URI = "http://localhost:3000/account/login";
  const onSubmitHandler = (formData) => {
    console.log({ formData });
    dispatch(signUpScheduler({ formData }));
  };
  const DELAY = 1500;
  const [captcha, setCaptcha] = useState({
    callback: "not fired",
    value: "[empty]",
    load: false,
    expired: "false",
    recaptchaLoaded: false,
  });
  // const validation = z.object({
  //   username: z.email(),
  //   password: z.any(),
  // });
  // const validate = (field) => {
  //   validation.parse({ username: field });
  // };

  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);

  const handleChange = (value) => {
    console.log("onChange prop - Captcha value:", value);
    setCaptcha({ value });
    if (value === null) setCaptcha({ expired: "true" });
  };

  const asyncScriptOnLoad = () => {
    setCaptcha({ callback: "called!", recaptchaLoaded: true });
    console.log("scriptLoad - reCaptcha Ref-", recaptchaRef);
  };

  useEffect(() => {
    if (signUpLoading === API_STATUS.FULFILLED) {
      toast.dismiss();
      toast.success("Registered Successfully!");
      reset();
      navigate("/");
    }
    // if (signUpLoading === API_STATUS.REJECTED) {
    //   toast.dismiss();
    //   toast.error("Registration Failed!");
    // }
    if (errorMessage) {
      toast.dismiss();
      if (errorMessage.message) toast.error(errorMessage.message);
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        toast.error(data);
      }
      dispatch(clearErrormsg());
    }
  }, [signUpLoading, errorMessage]);
  return (
    <>
      <section>
        <div className="flex sign_page">
          <div className="col-md-12 col-lg-6 col-sm-12 col-xs-12 p-0">
            <div className="sign_img signup flex align-center">
              <div className="sign_img_cnt">
                <img src={logo2} class="logo-dark" alt="images" />
                <h1>Create a Workplace Culture that Inspires and Innovates.</h1>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-sm-12 col-xs-12 p-0">
            <div className="sign_content flex align-center">
              <div className="sign_info signup">
                <h3>Create an account</h3>
                <p>
                  Already have an account?{" "}
                  <Link to="/" style={{ color: "#323232!important" }}>
                    Sign in
                  </Link>
                </p>

                <div className="sign_form">
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="row">
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 pl-1" style={{ paddingRight: "15px" }}>
                        <div className="form-group">
                          <label htmlFor="">Name</label>
                          <input
                            type="text"
                            name="name"
                            {...register("name")}
                          />
                          {errors?.name?.message && (
                            <p className="error">{errors.name.message}</p>
                          )}
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="">DOB</label>
                          <input type="date" name="dob" {...register("dob")} />
                          {errors?.dob?.message && (
                            <p className="error">{errors.dob.message}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style={{ paddingRight: "15px" }}>
                        <div className="form-group">
                          <label htmlFor="">Phone</label>
                          <input
                            type="text"
                            name="phone_no"
                            {...register("phone_no")}
                          />
                          {errors?.phone_no?.message && (
                            <p className="error">{errors.phone_no.message}</p>
                          )}
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="">Email Address</label>
                          <input
                            type="text"
                            name="email"
                            {...register("email")}
                          />
                          {errors?.email?.message && (
                            <p className="error">{errors.email.message}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style={{ paddingRight: "15px" }}>
                        <div className="form-group">
                          <label htmlFor="">Create Your Own Password</label>
                          <input
                            type="password"
                            name="password"
                            {...register("password")}
                          />
                          <span>
                            Use Upper case, Lower case, Numbers & Symbols.Password length should be minimum 8 characters.
                          </span>
                          {errors?.password?.message && (
                            <p className="error">{errors.password.message}</p>
                          )}
                        </div>
                      </div>

                      {/* <div className="form-group">
                      <label htmlFor="">Address</label>
                      <textarea
                        type="text"
                        name="address"
                        {...register("address")}
                      ></textarea>
                      {errors?.address?.message && (
                        <p className="error">{errors.address.message}</p>
                      )}
                    </div> */}

                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="">College</label>
                          <select {...register("college")}>
                            <option value="">Choose College</option>
                            {collegeData && collegeData.map((collegeValue) => {
                              return (
                                <>
                                  <option value={collegeValue?.name}>
                                    {collegeValue?.name}
                                  </option>
                                </>
                              )
                            }
                            )}
                          </select>
                          {/* <input type="text" name="college" {...register("college")} /> */}
                          {errors?.college?.message && (
                            <p className="error">{errors.college.message}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">

                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style={{ paddingRight: "15px" }}>
                        <div className="form-group">
                          <label htmlFor="">College Code</label>
                          <input
                            type="text"
                            name="college_code"
                            {...register("college_code")}
                          />
                          {errors?.college_code?.message && (
                            <p className="error">{errors.college_code.message}</p>
                          )}
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style={{ paddingRight: "15px" }}>
                        <div className="form-group">
                          <label htmlFor="">Course</label>
                          <select {...register("course")}>
                            <option value="">Choose Course</option>
                            <option value="Arts and Science">Arts and Science</option>
                            <option value="BE">B.E</option>
                            <option value="B.Tech">B.Tech</option>
                            <option value="MBA">MBA</option>
                          </select>
                          {/* <input type="text" name="college" {...register("college")} /> */}
                          {errors?.course?.message && (
                            <p className="error">{errors.course.message}</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="">Specialization</label>
                          <input
                            type="text"
                            name="specialization"
                            {...register("specialization")}
                          />
                          {errors?.specialization?.message && (
                            <p className="error">
                              {errors.specialization.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12" style={{ paddingRight: "15px" }}>
                        <div className="form-group">
                          <label htmlFor="">Year</label>
                          <select name="year" {...register("year")}>
                            <option value="">Choose year</option>
                            <option value="1st Year">1st Year</option>
                            <option value="2nd Year">2nd Year</option>
                            <option value="3rd Year">3rd Year</option>
                            <option value="4th Year">4th Year</option>
                          </select>
                          {/* <input type="text" name="college" {...register("college")} /> */}
                          {errors?.year?.message && (
                            <p className="error">{errors.year.message}</p>
                          )}
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                        <div className="form-group">
                          <label htmlFor="">Roll Number</label>
                          <input
                            type="text"
                            name="roll_no"
                            {...register("roll_no")}
                          />
                          {errors?.roll_no?.message && (
                            <p className="error">{errors.roll_no.message}</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="terms">
                      <p className="color_terms">
                        By Creating an account, you agree to our Terms of Use and Privacy Policy
                      </p>
                    </div>

                    <div className="">
                      <ReCAPTCHA
                        style={{ display: "inline-block" }}
                        theme="dark"
                        size="invisible"
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_G_CAPTCHA || ""}
                        onChange={handleChange}
                        asyncScriptOnLoad={asyncScriptOnLoad}
                      />
                    </div>

                    <input
                      type="submit"
                      name="signin"
                      value="Create an account"
                      className="sign_button"
                      disabled={!captcha.recaptchaLoaded}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUp;
