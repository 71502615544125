import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { signUpValidationSchema } from "../utils/Validation";
import ReCAPTCHA from "react-google-recaptcha";
import { LoginSocialGoogle, LoginSocialTwitter } from "reactjs-social-login";
import { toast } from "react-toastify";
import {
    signUpScheduler,
    signUpSelector,
    clearErrormsg,
} from "../store/reducer/SignUpReducer";
import { API_STATUS } from "../utils/Constants";
import { Header } from "./layout/Header";

import Footer from "./layout/Footer";
import PlainFooter from "./layout/PlainFooter";
import b1 from "../webassets/images/banner/banner-one-shape-1.png";
import b2 from "../webassets/images/banner/banner-one-shape-2.png";
import b3 from "../webassets/images/banner/banner-one-shape-3.png";
import {
    testLists,
    questionLists,
    placementSelector,
    departmentLists,
    answerAdds
} from "../store/reducer/PlacementReducer";
import { getStudent, StudentSelector } from "../store/reducer/StudentReducer";
import { API_BASE } from "../services/config";
import backGroundImage from "../assets/img/profile-bg.png";
import profile from "../assets/img/avatar.webp";

const StudentProfile = () => {
    const recaptchaRef = React.createRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { signUpData, signUpLoading } = useSelector(signUpSelector);
    const { questionData, answerLoading, errorMessage } = useSelector(placementSelector);
    const { StudentDatas } = useSelector(StudentSelector);
    let [answers, setAnswers] = useState([]);
    const [isOpen, setIsOpen] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(signUpValidationSchema),
    });

    useEffect(() => {
        let formData = {};
        dispatch(getStudent({ formData }));
    }, [])

    useEffect(() => {
        console.log(StudentDatas, "sdfsdfdasfasd");        
    }, [StudentDatas]);


    const handleOpen = () => {
        setIsOpen(!isOpen)
    }

    const logoutuser = () => {
        // dispatch(logOut());
    }

    return (
        <>
            <Header />
            <div class="page-banner-area events-pages event-details" style={{ paddingBottom: "50px", paddingTop: "50px" }} >
                <div class="page-banner-shape-1">
                    <img src={b1} alt="images" />
                </div>
                <div class="page-banner-shape-2">
                    <img src={b2} alt="images" />
                </div>
                <div class="page-banner-shape-3">
                    <img src={b3} alt="images" />
                </div>
            </div>

            <section class="pad_100">
                <div class="container">
                    <div class="row">
                        < div class="conference-planning-area">
                            <div class="container">
                                <div class="user">
                                    <div class="card w-100 mb-3">
                                        <div class="card-body">
                                            <div class="head-card">
                                                <div class="profile-banner">
                                                    <div class="profile-img">
                                                        <img src={(StudentDatas?.student_details[0]?.background_image) ? API_BASE + StudentDatas?.student_details[0]?.background_image : backGroundImage} alt="Background Image" width="100%" />
                                                    </div>
                                                    <div class="edit-icon">
                                                        <i class='bx bxs-pencil'></i>
                                                    </div>
                                                </div>

                                                <div class="head-content">
                                                    <div class="profile-user">
                                                    <img src={profile} height="100" width="100" alt="Profile Image"/>
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24" style={{ fill: "rgba(236, 100, 43, 1);transform: scaleX(-1);msFilter:progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);" }}><path d="M12 2C6.579 2 2 6.579 2 12s4.579 10 10 10 10-4.579 10-10S17.421 2 12 2zm0 5c1.727 0 3 1.272 3 3s-1.273 3-3 3c-1.726 0-3-1.272-3-3s1.274-3 3-3zm-5.106 9.772c.897-1.32 2.393-2.2 4.106-2.2h2c1.714 0 3.209.88 4.106 2.2C15.828 18.14 14.015 19 12 19s-3.828-.86-5.106-2.228z"></path></svg> */}
                                                    </div>
                                                    <div class="profile-content head-profile">
                                                        <p class="name"><b>Your Name</b></p>
                                                        <p class="test">Test</p><i class='bx bx-pencil'></i>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-3 col-sm-3">
                                                        <div class="profile-content-icon">
                                                            <p><i class='bx bx-map'></i> Current Location</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-3 col-sm-3">
                                                        <div class="profile-content-icon">
                                                            <p><i class='bx bx-briefcase' ></i> Industry</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-3 col-sm-3">
                                                        <div class="profile-content-icon">
                                                            <p><i class='bx bx-user-check' ></i> Fresher</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-3 col-sm-3">
                                                        <div class="profile-content-icon">
                                                            <p><i class='bx bx-timer'></i> Notice Period</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <div class="profile-content">
                                            <p class="name"><b>Phone Number</b><i class='bx bx-pencil'></i></p>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row sb">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="content-attribute">
                                                    <p class="mail"><i class='bx bx-envelope'></i> name@gmail.com</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4 col-sm-4">
                                                <div class="content-attribute-edit">
                                                    <p><i class='bx bxl-linkedin' ></i> <i class='bx bxl-github'></i> <b style={{ color: "black;" }}>Edit Links</b></p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <div class="profile-content">
                                            <p class="name"><b>Profile Complete</b><i class='bx bx-pencil'></i></p>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-lg-9 col-md-9 col-sm-9">
                                                <div class="progress-width">
                                                    <div style={{ width: "70%;" }} class="progress-wrap progress" data-progress-percent="25">
                                                        <div class="progress-bar progress"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-3 col-sm-3">
                                                <div class="content-attribute">
                                                    <div class="progress-right">
                                                        <p class="percent">17%</p>
                                                        <p class="complete">complete</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="card">
                                    <div class="card-header">
                                        <div class="profile-content">
                                            <p class="name"><b>About</b><i class='bx bx-pencil'></i></p>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <div class="profile-content">
                                            <p class="name"><b>Skills</b><i class='bx bx-pencil'></i></p>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="content-attribute">
                                            <p>Add Skill.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <div class="profile-content">
                                            <p class="name"><b>Experience</b><i class='bx bx-pencil'></i></p>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="content-attribute">
                                            <p>Add Past Experience Details.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <div class="profile-content">
                                            <p class="name"><b>Education</b><i class='bx bx-pencil'></i></p>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="content-attribute">
                                            <p>Add Education Details.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <div class="profile-content">
                                            <p class="name"><b>Projects</b><i class='bx bx-pencil'></i></p>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="content-attribute">
                                            <p>Add Projects.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <div class="profile-content">
                                            <p class="name"><b>Training Institution</b><i class='bx bx-pencil'></i></p>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="content-attribute">
                                            <p>Add Training Institute Experience.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <div class="profile-content">
                                            <p class="name"><b>Certification</b><i class='bx bx-pencil'></i></p>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="content-attribute">
                                            <p>Add Certifications.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <div class="profile-content">
                                            <p class="name"><b>Job Preference</b><i class='bx bx-pencil'></i></p>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="content-attribute">
                                            <p>Looking For Full Time</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header">
                                        <div class="profile-content">
                                            <p class="name"><b>Personal Details</b><i class='bx bx-pencil'></i></p>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="content-attribute">
                                            <p><i class='bx bx-user'></i></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section >
            {/* <Footer /> */}
            <PlainFooter />
        </>
    );
};

export default StudentProfile;
