import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import { hiring_partner_list, add_hiring_partner, get_hiring_partner, edit_hiring_partner } from "../../services/api";

const namespace = "hiringPartner";

const initialState = {
  loading: "initial",
  errorMessage: "",
  hiringPartnerDatas: null,
  hiringPartnerEditData: null,
  hiringPartnerLoading: "initial",
  hiringPartnerListLoading: "initial",
  hiringPartnerEditLoading: "initial",
  count: 0,
};


export const hiring_partnerLists = createAsyncThunk(
  `${namespace}/hiring_partnerLists`,
  async ({ query, limit, page }, { rejectWithValue, dispatch }) => {
    try {
      console.log(query, limit, page, "query,limit,page");
      const { data } = await hiring_partner_list(query, limit, page);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const addHiringPartner = createAsyncThunk(
  `${namespace}/addHiringPartner`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData");
      const { data } = await add_hiring_partner(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);


export const getHiringPartner = createAsyncThunk(
  `${namespace}/getHiringPartner`,
  async ({ hiringPartnerId }, { rejectWithValue, dispatch }) => {
    try {
      // console.log(formData, "formData");
      const { data } = await get_hiring_partner(hiringPartnerId);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);


export const EditHiringPartnerData = createAsyncThunk(
  `${namespace}/EditHiringPartnerData`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "EditHiringPartnerDataformData");
      const { data } = await edit_hiring_partner(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);



const hiringPartnerSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearHiringPartnerData: () => {
      return initialState;
    },
    clearData: (state) => {
      state.hiringPartnerLoading = "initial";
      state.hiringPartnerListLoading = "initial";
      state.hiringPartnerEditLoading = "initial"
    }
  },

  extraReducers: (builder) => {
    builder.addCase(hiring_partnerLists.pending, (state) => {
      state.hiringPartnerListLoading = API_STATUS.PENDING;
    });
    builder.addCase(hiring_partnerLists.fulfilled, (state, { payload }) => {
      state.hiringPartnerListLoading = API_STATUS.FULFILLED;
      state.hiringPartnerDatas = payload?.data;
      console.log(payload, "hiringPartnerDatas");
    });
    builder.addCase(hiring_partnerLists.rejected, (state, action) => {
      state.hiringPartnerListLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(addHiringPartner.pending, (state) => {
      state.hiringPartnerLoading = API_STATUS.PENDING;
    });
    builder.addCase(addHiringPartner.fulfilled, (state, { payload }) => {
      state.hiringPartnerLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(addHiringPartner.rejected, (state, action) => {
      state.hiringPartnerLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(getHiringPartner.pending, (state) => {
      state.hiringPartnerListLoading = API_STATUS.PENDING;
    });
    builder.addCase(getHiringPartner.fulfilled, (state, { payload }) => {
      state.hiringPartnerListLoading = API_STATUS.FULFILLED;
      state.hiringPartnerEditData = payload?.data;
      console.log(payload, "hiringPartnerEditData");
    });
    builder.addCase(getHiringPartner.rejected, (state, action) => {
      state.hiringPartnerListLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(EditHiringPartnerData.pending, (state) => {
      state.hiringPartnerEditLoading = API_STATUS.PENDING;
    });
    builder.addCase(EditHiringPartnerData.fulfilled, (state, { payload }) => {
      state.hiringPartnerEditLoading = API_STATUS.FULFILLED;
    });
    builder.addCase(EditHiringPartnerData.rejected, (state, action) => {
      state.hiringPartnerEditLoading = API_STATUS.REJECTED;
      console.log(action.payload,"EditHiringPartnerData");
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const { clearHiringPartnerData, clearData } = hiringPartnerSlice.actions;

export const hiringpartnerSelector = (state) => state.hiringPartner;

export default hiringPartnerSlice.reducer;
