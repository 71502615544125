import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { myAnswerSchema } from "../utils/Validation";
import ReCAPTCHA from "react-google-recaptcha";
import { LoginSocialGoogle, LoginSocialTwitter } from "reactjs-social-login";
import { toast } from "react-toastify";
import {
  signUpScheduler,
  signUpSelector,
  clearErrormsg,
} from "../store/reducer/SignUpReducer";
import { API_STATUS } from "../utils/Constants";
import { Header } from "./layout/Header";
import Footer from "./layout/Footer";
import b1 from "../webassets/images/banner/banner-one-shape-1.png";
import b2 from "../webassets/images/banner/banner-one-shape-2.png";
import b3 from "../webassets/images/banner/banner-one-shape-3.png";

import {
  testLists,
  questionLists,
  placementSelector,
  departmentLists,
  answerAdds,
  studentLists,
  downloadReports,
} from "../store/reducer/PlacementReducer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "../utils/pagination";
import { StyledTableCell, StyledTableRow } from "../utils/tableRows";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const Answers = () => {
  const recaptchaRef = React.createRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signUpData, signUpLoading } = useSelector(signUpSelector);
  const {
    questionData,
    answerLoading,
    studentDatas,
    departmentData,
    errorMessage,
    reportsData,
  } = useSelector(placementSelector);

  let [sortBy, setSortBy] = useState("name");
  const [college, setCollege] = useState("");
  let [activesortBy, setActiveSortBy] = useState("name");
  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [order, setOrder] = useState("asc");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(myAnswerSchema),
  });

  const toAnswer = (id) => {
    // console.log(id, "USDREESFS");
    // navigate("/answers/" + id);
    window.location.href = "/answers/" + id;
  };

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/");
    }
  });

  const REDIRECT_URI = "http://localhost:3000/account/login";
  const onSubmitHandler = (formData) => {
    console.log({ formData });
    dispatch(signUpScheduler({ formData }));
  };
  const DELAY = 1500;
  const [captcha, setCaptcha] = useState({
    callback: "not fired",
    value: "[empty]",
    load: false,
    expired: "false",
    recaptchaLoaded: false,
  });

  const sortByKey = (key) => {
    sortBy = key;
    if (activesortBy == key) {
      if (order == "desc") {
        order = "asc";
      } else {
        order = "desc";
      }
      setOrder(order);
    } else {
      order = "desc";
      activesortBy = key;
      setActiveSortBy(key);
      setOrder(order);
    }
    setSortBy(key);
  };

  useEffect(() => {
    let formData = {};
    dispatch(departmentLists({}));
    dispatch(studentLists({ formData }));
  }, []);

  const departmentdata = (department) => {
    if (departmentData.length > 0) {
      let data = departmentData.map((fields) => {
        if (department == fields.id) {
          return fields.name;
        }
      });
      return data;
    }
  };

  const downloadReport = () => {
    if (college != "") {
      dispatch(downloadReports({ student: 0, college: college }));
    }else{
      toast.dismiss();
      toast.error("Select College");
    }
  };
  const downloadStudent = () => {
    if (college != "") {
      dispatch(downloadReports({ student: 1, college: college }));
    }else{
      toast.dismiss();
      toast.error("Select College");
    }
  };

  useEffect(() => {
    console.log(studentDatas, "studentDatas");
  }, [studentDatas]);

  useEffect(() => {
    if (errorMessage) {
      toast.dismiss();
      if (errorMessage.message) toast.error(errorMessage.message);
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        toast.error(data);
      }
      dispatch(clearErrormsg());
    }
  }, [errorMessage]);

  const handleChangeRowsPerPage = (event) => {
    page = 0;
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(newPage);
  };

  useEffect(() => {
    console.log(reportsData, "reportsData");
    if (reportsData && reportsData.length > 0) {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileName = "Student Reports";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(reportsData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  }, [reportsData]);

  return (
    <>
      <Header />
      <div
        class="page-banner-area events-pages event-details"
        style={{ paddingBottom: "50px", paddingTop: "50px" }}
      >
        <div class="container-fluid">
          <div class="single-page-banner-content">
            <h1>Pre-Placement Drive</h1>
            <ul>
              <li>
                <Link class="nav-link active" to={`/inter`}>
                  Home
                </Link>
              </li>
              <li>Pre-Placement Driv</li>
            </ul>
          </div>
        </div>
        <div class="page-banner-shape-1">
          <img src={b1} alt="images" />
        </div>
        <div class="page-banner-shape-2">
          <img src={b2} alt="images" />
        </div>
        <div class="page-banner-shape-3">
          <img src={b3} alt="images" />
        </div>
      </div>

      <section class="pad_100">
        <div class="container">
          <div class="row">
            <div class="section-title left-title">
              {/* <h2>
                Placement Officer Survey: Understanding IT Industry Expectations
                and Student Mindset
              </h2>
              <p>
                Thank you for participating in this survey. Your insights are
                invaluable in shaping the future of placements at our college.
                The information gathered will help us enhance our understanding
                of the IT industry's expectations and the factors influencing
                students success in job interviews.
              </p> */}
            </div>

            <div class="col-md-12">
              <h2 class="sub_heading">Student List</h2>
              <div class="col-md-12 mb-2">
                <div className="form-group mb-2">
                  <label htmlFor="">College</label>
                  <select
                    className="form-select"
                    onChange={(e) => {
                      setCollege(e.target.value);
                    }}
                  >
                    <option value="">Choose College</option>
                    <option value="Firebird Institute">
                      Firebird Institute
                    </option>
                    <option value="SRS College">SRS College</option>
                  </select>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    downloadReport();
                  }}
                >
                  Download Report
                </button>
                &nbsp;&nbsp;
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    downloadStudent();
                  }}
                >
                  Download Students
                </button>
              </div>
              <div className="table-responsive react-table">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} stickyHeader>
                    <TableHead className="table-light table-nowrap">
                      <TableRow>
                        <StyledTableCell align="">Name</StyledTableCell>
                        <StyledTableCell align="">College</StyledTableCell>
                        <StyledTableCell align="">Course</StyledTableCell>
                        <StyledTableCell align="">
                          Specialization
                        </StyledTableCell>
                        <StyledTableCell align="">Year</StyledTableCell>
                        <StyledTableCell align="">Profile</StyledTableCell>
                        <StyledTableCell align="">Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {studentDatas &&
                        studentDatas.map((row) => (
                          <StyledTableRow>
                            <StyledTableCell align="">
                              {row?.name}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.college}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.course}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.specialization}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {row?.year}
                            </StyledTableCell>
                            <StyledTableCell align="">
                              {departmentdata(row?.department)}
                            </StyledTableCell>
                            <StyledTableCell align="" className="button-items">
                              {/* <a 
                                title="Edit"
                                className="btn btn-primary"
                                onClick={(e) => {
                                  toAnswer(row?.id);
                                }}
                              >
                                <i className="fas fa-cloud"></i>
                              </a> */}
                              <a
                                title="View Answers"
                                href="javascript:void(0);"
                                class="btn btn-style-one btn-primary"
                                onClick={(e) => {
                                  toAnswer(row?.id);
                                }}
                              >
                                View Answers
                              </a>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 50]}
                          colSpan={8}
                          count={0}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Answers;
