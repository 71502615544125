import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  ClipboardEvent,
} from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { myAnswerSchema } from "../utils/Validation";
import ReCAPTCHA from "react-google-recaptcha";
import { LoginSocialGoogle, LoginSocialTwitter } from "reactjs-social-login";
import { toast } from "react-toastify";
import { InnerHeader } from "./layout/InnerHeader";
import PlainFooter from "./layout/PlainFooter";
import {
  signUpScheduler,
  signUpSelector,
  clearErrormsg,
} from "../store/reducer/SignUpReducer";
import { API_STATUS } from "../utils/Constants";
import { Header } from "./layout/Header";
import Footer from "./layout/Footer";
import b1 from "../webassets/images/banner/banner-one-shape-1.png";
import b2 from "../webassets/images/banner/banner-one-shape-2.png";
import b3 from "../webassets/images/banner/banner-one-shape-3.png";
import {
  testLists,
  questionLists,
  placementSelector,
  departmentLists,
  answerAdds,
} from "../store/reducer/PlacementReducer";

import {
  Modal
} from "reactstrap";
import logo2 from "../webassets/images/Asset 7.png";
import ProgressBar from "react-bootstrap/ProgressBar";
const Question = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [words, setWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [userAnswer, setUserAnswer] = useState("");
  const [answerCount, setAnswerCount] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [timer, setTimer] = useState("00:00");
  let [answers, setAnswers] = useState([]);
  const { questionData, answerLoading, errorMessage } =
    useSelector(placementSelector);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(myAnswerSchema),
  });

  const preventCopyPaste = (e) => {
    e.preventDefault();
    toast.dismiss();
    toast.warning("Drag & Drop, copy, cut, paste are not allowed!");
  };

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      clearInterval(Ref.current);
      navigate("/");
    } else {
    }
  }, []);


  useEffect(() => {
    let department_id = localStorage.getItem("departmentID");
    let question_group_id = localStorage.getItem("question_group_id");
    dispatch(
      questionLists({ department_id: department_id, question_group_id: "1" })
    );
    // dispatch(departmentLists({}));
  }, []);


  const handleAnswerChange = (event) => {
    setUserAnswer(event.target.value);
  };

  const handleNextWord = (questionDatas, questionIndex) => {
    let qindex = questionIndex + 1
    if (qindex < questionData.length) {
      setCurrentWordIndex(qindex);
      if (answers[qindex] && (answers[qindex]?.answer != "")) { return true; } else {
        answers[qindex] = {
          question_id: questionDatas[qindex]?.id,
          answer: "",
        }
      }
    } else {
      // setShowResult(true);
      // tog_fullscreen();
      submitAnswer(1);
    }
  };
  const handlePrevWord = () => {
    setCurrentWordIndex(currentWordIndex - 1);
  };

  const resetQuiz = () => {
    setCurrentWordIndex(0);
    setScore(0);
    setUserAnswer("");
    setShowResult(false);
  };
  useEffect(() => {
    if (localStorage.getItem("answers")) {
      let storageAnswer = JSON.parse(localStorage.getItem("answers"));

      storageAnswer.map((answerValues, indexs) => {
        if (answerValues)
          answers[indexs] = {
            question_id: answerValues.question_id,
            answer: answerValues.answer,
          };
      });
    }
  }, [])

  const submitAnswer = (type) => {
    // Perform validation on the input array here
    let submit = true;
    const all_datas = questionData.map((question, questionIndexSubmit) => {
      if (typeof answers[questionIndexSubmit] === "undefined") {
        submit = false;
        answers[questionIndexSubmit] = { question_id: question.id, answer: "" };
        setAnswers([...answers]);
      } else if (
        typeof answers[questionIndexSubmit] !== "undefined" &&
        answers[questionIndexSubmit]?.answer == ""
      ) {
        submit = false;
      }
    });

    Promise.all(all_datas).then(() => {
      if (type == 2) {
        submit = true;
      }
      if (type == 1 && submit == false) {
        toast.dismiss();
        toast.error("Answer all the questions to submit!");
        return false;
      }
      let datas = answers.filter(function (element) {
        return element !== undefined;
      });

      let formData = { answers: datas };
      dispatch(answerAdds({ formData }));
    });
  };

  const updateAnswers = (question, answer) => {
    answers[currentWordIndex] = { question_id: question.id, answer: answer };
    setAnswers([...answers]);
    localStorage.setItem("answers", JSON.stringify(answers));
  };
  useEffect(() => {
    let data_count = 0;
    answers.map((ans) => {
      if (typeof ans !== "undefined" && typeof ans.answer !== "undefined" && ans.answer && ans.answer !== "") {
        data_count = parseInt(data_count) + parseInt(1);
      }
    });
    setAnswerCount(data_count);
  }, [answers]);

  useEffect(() => {
    if (questionData && questionData.length > 0) {
      setQuestions(questionData);
      let startTime = !localStorage.getItem("timerVal")
        ? 1800
        : localStorage.getItem("timerVal");
      clearTimer(getDeadTime(startTime));
    }
  }, [questionData]);


  useEffect(() => {
    if (answerLoading === API_STATUS.FULFILLED) {
      toast.dismiss();
      toast.success("Answer Submitted Successfully!");
      reset();

      // if (Ref.current) {
      //   clearInterval(Ref.current);
      // }
      localStorage.clear();
      // navigate('/thankyou');
      window.location.href = "/thankyou";
    }
    if (errorMessage) {
      toast.dismiss();
      if (errorMessage.message) toast.error(errorMessage.message);
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        toast.error(data);
      }
      dispatch(clearErrormsg());
    }
  }, [answerLoading, errorMessage]);

  /** Timer Settings */

  const Ref = useRef(0);
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    // console.log(total);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
      let sec = parseFloat(minutes * 60) + parseFloat(seconds);
      localStorage.setItem("timerVal", sec);
    } else {
      console.log(questionData, "questiondata")
      submitAnswer(2);
    }
  };
  // Clear The Timer
  const clearTimer = (e) => {
    setTimer("00:00");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = (startTime) => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + parseInt(startTime));
    return deadline;
  };
  const [state, setState] = useState({ modal_fullscreen: false });

  const tog_fullscreen = () => {
    setState((prevState) => ({
      modal_fullscreen: !prevState.modal_fullscreen,
    }));
    removeBodyCss();
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  // const inputElement = useRef([]);

  // useEffect(() => {
  //   if (inputElement[currentWordIndex]?.current) {
  //     inputElement[currentWordIndex].current.focus();
  //   }
  //   console.log(inputElement[currentWordIndex].current,"CURREMDT");
  // }, []);

  return (

    <div class="questions_page">
    <InnerHeader page="1"/>
      <Modal
        size="xl"
        isOpen={state.modal_fullscreen}
        toggle={tog_fullscreen}
      >
        {questionData && questionData.map((questions, questionIndex) => {
          if (currentWordIndex == questionIndex)
            return <div class="container">
              <div class="col-md-12">
                <div class="form_check">
                  <h5 class="question-p">
                    {" "}
                    {questions?.question}
                  </h5>
                  {questions.type === 1 ? (
                    <textarea
                      placeholder="Your Answer"
                      // ref={inputElement[questionIndex]} 
                      name="answers[]"
                      rows="5"
                      cols="30"
                      readOnly
                      onPaste={preventCopyPaste}
                      onCopy={preventCopyPaste}
                      onCut={preventCopyPaste}
                      onDrop={preventCopyPaste}
                      onChange={(e) => {
                        updateAnswers(
                          questions,
                          e.target.value
                        );
                      }}
                      value={answers[questionIndex]?.answer}
                    ></textarea>
                  ) : questions.type === 4 ? (
                    <input type="text" name="answers[]" placeholder="Your Answer" readOnly onPaste={preventCopyPaste}
                      onCopy={preventCopyPaste}
                      onCut={preventCopyPaste}
                      onDrop={preventCopyPaste}
                      onChange={(e) => {
                        updateAnswers(
                          questions,
                          e.target.value
                        );
                      }}

                      value={
                        answers[questionIndex]?.answer == " "
                          ? ""
                          : answers[questionIndex]?.answer
                      } />
                  ) : (
                    ""
                  )}
                  <div className="row">
                    <div style={{ textAlign: "left", margin: "auto" }} className="col-md-6">
                      {questionIndex > 0 ? (
                        <button
                          name="question_submit"
                          className="ques_prev"
                          onClick={handlePrevWord}
                        >
                          <span class="btnText">Previous</span>
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      style={{ textAlign: "right", margin: "auto" }}
                      className="col-md-6"
                    >
                      <button name="answer_edit" onClick={() => setState({ modal_fullscreen: false })}
                        type="button"
                        className="close mx-2 mb-2"
                        data-dismiss="modal"
                        aria-label="Close"

                      >
                        <span class="btnText">Edit Answer
                        </span> </button>
                      {questionIndex + 1 == questionData?.length ?
                        <button name="question_submit" onClick={() => submitAnswer(1)}
                          className=""
                        ><span class="btnText">Submit</span> </button>
                        : <button
                          name="question_submit"
                          onClick={() => handleNextWord(questionData, questionIndex)}
                          style={{ float: "right" }}
                        >
                          <span class="btnText">Next </span>
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
        })}
      </Modal >
      <div
        class="page-banner-area events-pages event-details"
        style={{ paddingBottom: "10px", paddingTop: "10px" }}
      >
        <div class="container-fluid">
          <div class="single-page-banner-content">
            <h1>Pre-Placement Assessment</h1>
          </div>
        </div>
      </div>
      <div class="pt_80">
        <div class="progressbar-div">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <p>
                  {" "}
                  Question {currentWordIndex + 1} of {questionData?.length}{" "}
                </p>
              </div>
              <div class="col-lg-6">
                <div class="space-h3">
                  {/* <p>Hello {localStorage.getItem("username")}</p> */}
                  <h3>Time left: {timer} </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="col-md-12">
            <ProgressBar now={(answerCount / questionData?.length) * 100} animated variant="progress-wrap" />
          </div>
        </div>
        {questionData && questionData.map((questions, questionIndex) => {
          if (currentWordIndex == questionIndex)
            return <div class="container">
              <div class="col-md-12">
                <div class="form_check">
                  <h5 class="question-p">
                    {" "}
                    {questions?.question}
                  </h5>
                  {questions.type === 1 ? (
                    <textarea
                      placeholder="Your Answer"
                      name="answers[]"
                      rows="5"
                      cols="30"
                      onPaste={preventCopyPaste}
                      onCopy={preventCopyPaste}
                      onCut={preventCopyPaste}
                      onDrop={preventCopyPaste}
                      onChange={(e) => {
                        updateAnswers(
                          questions,
                          e.target.value
                        );
                      }}
                      value={answers[questionIndex]?.answer}
                    ></textarea>
                  ) : questions.type === 4 ? (
                    <input type="text" name="answers[]" placeholder="Your Answer" onPaste={preventCopyPaste}
                      onCopy={preventCopyPaste}
                      onCut={preventCopyPaste}
                      onDrop={preventCopyPaste}
                      onChange={(e) => {
                        updateAnswers(
                          questions,
                          e.target.value
                        );
                      }}
                      value={
                        answers[questionIndex]?.answer == " "
                          ? ""
                          : answers[questionIndex]?.answer
                      } />
                  ) : (
                    ""
                  )}
                  <div className="row">
                    <div
                      style={{ textAlign: "left", margin: "auto" }}
                      className="col-md-6"
                    >
                      {questionIndex > 0 ? (
                        <button
                          name="question_submit"
                          className="ques_prev"
                          onClick={handlePrevWord}
                        >
                          <span class="btnText">Previous</span>
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      style={{ textAlign: "right", margin: "auto" }}
                      className="col-md-6"
                    >
                      <button
                        name="question_submit"
                        onClick={() => handleNextWord(questionData, questionIndex)}
                        style={{ float: "right" }}
                      >
                        <span class="btnText">
                          {questionIndex + 1 == questionData?.length
                            ? "Submit"
                            : "Next"}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        })}
      </div>
        <PlainFooter />
    </div >
  );
};

export default Question;
