import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import { signUp, checkUser,college_list } from "../../services/api";

const namespace = "signUpConfiguration";

const initialState = {
  loading: "initial",
  errorMessage: "",
  signUpData: null,
  signUpLoading: "initial",
  userExistLoading: "initial",
  count: 0,
};

export const signUpScheduler = createAsyncThunk(
  `${namespace}/signUpScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData)
      const { data } = await signUp(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const checkUserExist = createAsyncThunk(
  `${namespace}/checkUserExist`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await checkUser(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);


export const collegeList = createAsyncThunk(
  `${namespace}/collegeList`,
  async ({formData}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await college_list(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
)



const schedulerConfigSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearSignUpData: () => {
      return initialState;
    },
    clearErrormsg: (state) => {
      state.errorMessage = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(signUpScheduler.pending, (state) => {
      state.signUpLoading = API_STATUS.PENDING;
    })
    builder.addCase(signUpScheduler.fulfilled, (state, { payload }) => {
      state.signUpLoading = API_STATUS.FULFILLED;
      state.signUpData = payload;
    })
    builder.addCase(signUpScheduler.rejected, (state, action) => {
      state.signUpLoading = API_STATUS.REJECTED;
      console.log(action?.payload,"payload")
      state.errorMessage = action?.payload?.data;
    })
    builder.addCase(checkUserExist.pending, (state) => {
      state.userExistLoading = API_STATUS.PENDING;
    })
    builder.addCase(checkUserExist.fulfilled, (state, { payload }) => {
      state.userExistLoading = API_STATUS.FULFILLED;
      state.errorMessage = "success";
    })
    builder.addCase(checkUserExist.rejected, (state, action) => {
      state.userExistLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    })
    builder.addCase(collegeList.pending, (state) => {
      state.collegeLoading = API_STATUS.PENDING;
    })
    builder.addCase(collegeList.fulfilled, (state, { payload }) => {
      state.collegeLoading = API_STATUS.FULFILLED;
      state.errorMessage = "success";      
      state.collegeData = payload?.data;
    })
    builder.addCase(collegeList.rejected, (state, action) => {
      state.collegeLoading = API_STATUS.REJECTED;
      state.errorMessage = action?.payload?.data;
    })
  },
});

export const { clearSignUpData, clearErrormsg } = schedulerConfigSlice.actions;

export const signUpSelector = (state) => state.signUpConfiguration;

export default schedulerConfigSlice.reducer;
