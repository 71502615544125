import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  ClipboardEvent,
} from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { myAnswerSchema } from "../utils/Validation";
import ReCAPTCHA from "react-google-recaptcha";
import { LoginSocialGoogle, LoginSocialTwitter } from "reactjs-social-login";
import { toast } from "react-toastify";
import {
  signUpScheduler,
  signUpSelector,
  clearErrormsg,
} from "../store/reducer/SignUpReducer";
import { API_STATUS } from "../utils/Constants";
import { Header } from "./layout/Header";
import Footer from "./layout/Footer";
import b1 from "../webassets/images/banner/banner-one-shape-1.png";
import b2 from "../webassets/images/banner/banner-one-shape-2.png";
import b3 from "../webassets/images/banner/banner-one-shape-3.png";
import {
  testLists,
  questionLists,
  placementSelector,
  departmentLists,
  answerAdds,
} from "../store/reducer/PlacementReducer";
import logo2 from "../webassets/images/Asset 7.png";

const Quiz = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [words, setWords] = useState([]);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');
  const [showResult, setShowResult] = useState(false);
  // const [timer, setTimer] = useState("00:00");
  let [answers, setAnswers] = useState([]);
  const { questionData, answerLoading, errorMessage } =
    useSelector(placementSelector);


  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(myAnswerSchema),
  });

  const preventCopyPaste = (e) => {
    e.preventDefault();
    toast.dismiss();
    toast.warning("Dragging,Coping, Cut and Pasting is not allowed!");
  };


  useEffect(() => {
    // let department_id = localStorage.getItem("departmentID");
    let question_group_id = localStorage.getItem("question_group_id");
    let department_id = 1;

    dispatch(
      questionLists({ department_id: department_id, question_group_id: "1" })
    );
    // dispatch(departmentLists({}));
  }, []);
  // Sample vocabulary data
  const sampleWords = [
    { word: 'eloquent', definition: 'fluent or persuasive in speaking or writing' },
    { word: 'ubiquitous', definition: 'present, appearing, or found everywhere' },
    // Add more words as needed
  ];

  useEffect(() => {
    // Fetch or set your vocabulary data here
    setWords(sampleWords);
  }, []);

  const handleAnswerChange = (event) => {
    setUserAnswer(event.target.value);
  };

  const handleNextWord = () => {   
    if (currentWordIndex + 1 < questionData.length) {
      answers[currentWordIndex + 2] = { question_id: currentWordIndex + 2, answer: " " };

      setCurrentWordIndex(currentWordIndex + 1);
    } else {
      setShowResult(true);
      submitAnswer(1);
    }
  };

  const handlePrevWord = () => {
    setCurrentWordIndex(currentWordIndex - 1);
  };


  const resetQuiz = () => {
    setCurrentWordIndex(0);
    setScore(0);
    setUserAnswer('');
    setShowResult(false);
  };
  if (localStorage.getItem("answers")) {
    let storageAnswer = JSON.parse(localStorage.getItem("answers"));
    storageAnswer.map((answerValues) => {
      if (answerValues)
        answers[answerValues.question_id] = { question_id: answerValues.question_id, answer: answerValues.answer };
    });
  }

  const submitAnswer = (type) => {
    // Perform validation on the input array here
    let submit = true;
    const all_datas = questionData.map((question) => {
      if (typeof answers[question.id] === "undefined") {
        console.log("in");
        submit = false;
        answers[question.id] = { question_id: question.id, answer: '' };
        setAnswers([...answers]);
      } else if (
        typeof answers[question.id] !== "undefined" &&
        answers[question.id]?.answer == ""
      ) {
        submit = false;
      }
    });

    Promise.all(all_datas).then(() => {
      if (type == 2) {
        submit = true;
      }
      if (type == 1 && submit == false) {
        toast.dismiss();
        toast.error("All the fields are mandatory!");
        return false;
      }
      let datas = answers.filter(function (element) {
        return element !== undefined;
      });
      let formData = { answers: datas };
      console.log(formData, "Formdata");
      // dispatch(answerAdds({ formData }));
    });
  };

  const updateAnswers = (question, answer) => {
    // localStorage.setItem(question.id, answer);
    answers[question.id] = { question_id: question.id, answer: answer };

    // answers = answers.filter(function (element) {
    //   return element !== undefined;
    // });
    console.log(answers, "ASDFNDSFJ");

    localStorage.setItem("answers", JSON.stringify(answers));
    setAnswers([...answers]);
  };
  useEffect(() => {
    console.log(answers, "answers");
  }, [answers]);


  useEffect(() => {
    console.log(answerLoading, "answerLoading");
    if (answerLoading === API_STATUS.FULFILLED) {
      toast.dismiss();
      toast.success("Answer Submitted Successfully!");
      reset();

      // if (Ref.current) {
      //   console.log("indata");
      //   clearInterval(Ref.current);
      // }
      localStorage.clear();
      navigate("/thankyou");
    }
    if (errorMessage) {
      toast.dismiss();
      if (errorMessage.message) toast.error(errorMessage.message);
      else if (errorMessage.errors) {
        let data = "";
        errorMessage?.errors.map((err) => {
          data += err.message + " ";
        });
        toast.error(data);
      }
      dispatch(clearErrormsg());
    }
  }, [answerLoading, errorMessage]);


  /** Timer Settings */

  // const Ref = useRef(0);
  // const getTimeRemaining = (e) => {
  //   const total = Date.parse(e) - Date.parse(new Date());
  //   const seconds = Math.floor((total / 1000) % 60);
  //   const minutes = Math.floor((total / 1000 / 60) % 60);
  //   const hours = Math.floor((total / 1000 / 60 / 60) % 24);
  //   return {
  //     total,
  //     hours,
  //     minutes,
  //     seconds,
  //   };
  // };

  // const startTimer = (e) => {
  //   let { total, minutes, seconds } = getTimeRemaining(e);
  //   console.log(total);
  //   if (total >= 0) {
  //     setTimer(
  //       (minutes > 9 ? minutes : "0" + minutes) +
  //       ":" +
  //       (seconds > 9 ? seconds : "0" + seconds)
  //     );
  //     let sec = parseFloat(minutes * 60) + parseFloat(seconds);
  //     localStorage.setItem("timerVal", sec);
  //   } else {
  //     submitAnswer(2);
  //   }
  // };
  // // Clear The Timer
  // const clearTimer = (e) => {
  //   setTimer("00:00");
  //   if (Ref.current) clearInterval(Ref.current);
  //   const id = setInterval(() => {
  //     startTimer(e);
  //   }, 1000);
  //   Ref.current = id;
  // };

  // const getDeadTime = (startTime) => {
  //   let deadline = new Date();
  //   deadline.setSeconds(deadline.getSeconds() + parseInt(startTime));
  //   return deadline;
  // };

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      // clearInterval(Ref.current);
      navigate("/");
      return false;
    } else {
      // let startTime = !localStorage.getItem("timerVal")
      //   ? 1800
      //   : localStorage.getItem("timerVal");
      // console.log(startTime, "start");
      // localStorage.setItem("timerVal", 1800);
      // clearTimer(getDeadTime(startTime));
    }
  }, []);

  return (
    <div>
      <div>
        <div class="form mt-5">
          <div className="row">
            <div className="col-md-6"><img src={logo2} class="logo-dark" alt="images" width="250" /></div>
            <div className="col-md-6 text-end">
              {/* <h2>Time Left: {timer}</h2> */}
              <h2>Time Left: </h2>
            </div>
          </div>
          <div class="mt-2">
            <div className="row">
              <div className="col-md-6">
                <h2>Pre-Placement Drive</h2>
              </div>
              <div className="col-md-6 text-end">
                <p>Questin {currentWordIndex + 1} of {questionData?.length}</p>
              </div>
            </div>


            {(questionData?.[currentWordIndex]?.type === 1) ?
              <div class="form_group">
                <label>
                  <b>{questionData?.[currentWordIndex]?.question}</b>
                </label>
                <textarea
                  placeholder="Your Answer"
                  name="answers[]"
                  rows="10"
                  cols="30"
                  onPaste={preventCopyPaste}
                  onCopy={preventCopyPaste}
                  onCut={preventCopyPaste}
                  onDrop={preventCopyPaste}
                  onChange={(e) => {
                    updateAnswers(questionData?.[currentWordIndex], e.target.value);
                  }}
                  value={answers[currentWordIndex + 1]?.answer == ' ' ? " " : answers[currentWordIndex + 1]?.answer}

                ></textarea>
              </div>
              :
              <></>
            }
            {(questionData?.[currentWordIndex]?.type === 2) ?
              <div class="form_group">
                <label>
                  <b>{questionData?.[currentWordIndex]?.question}</b>
                </label>
                <input type="text" name="answers[]"
                  placeholder="Your Answer"
                  onPaste={preventCopyPaste}
                  onCopy={preventCopyPaste}
                  onCut={preventCopyPaste}
                  onDrop={preventCopyPaste}
                  onChange={(e) => {
                    updateAnswers(questionData?.[currentWordIndex + 1], e.target.value);
                  }}
                  defaultValue={answers[currentWordIndex + 1]}
                />
              </div>
              :
              <></>
            }
            <div className="row">
              <div style={{ textAlign: "left", margin: "auto" }} className="col-md-6">

                {((currentWordIndex + 1) > 1) ?
                  <button
                    name="question_submit"
                    class="submit_btn"
                    onClick={handlePrevWord}
                  >
                    <span class="btnText">Previous</span>
                  </button>
                  :
                  <></>
                }
              </div>
              <div style={{ textAlign: "right", margin: "auto" }} className="col-md-6">
                <button
                  name="question_submit"
                  class="submit_btn"
                  onClick={handleNextWord}
                >
                  <span class="btnText">{((currentWordIndex + 1) == questionData?.length) ? "Submit" : "Next"}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
