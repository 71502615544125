import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { myAnswerSchema } from "../utils/Validation";
import ReCAPTCHA from "react-google-recaptcha";
import { LoginSocialGoogle, LoginSocialTwitter } from "reactjs-social-login";
import { toast } from "react-toastify";
import {
    signUpScheduler,
    signUpSelector,
    clearErrormsg,
} from "../store/reducer/SignUpReducer";
import { API_STATUS } from "../utils/Constants";
import { Header } from "./layout/Header";
import Footer from "./layout/Footer";
import b1 from "../webassets/images/banner/banner-one-shape-1.png";
import b2 from "../webassets/images/banner/banner-one-shape-2.png";
import b3 from "../webassets/images/banner/banner-one-shape-3.png";
import {
    testLists,
    questionLists,
    answerLists,
    placementSelector,
    departmentLists,
    answerAdds,
} from "../store/reducer/PlacementReducer";

const Answers = () => {
    const recaptchaRef = React.createRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { signUpData, signUpLoading } = useSelector(signUpSelector);
    const { questionData, answerLoading, answerDatas, errorMessage } =
        useSelector(placementSelector);

    let { id } = useParams();

    useEffect(() => {
        dispatch(answerLists({ id }));
    }, [])
    useEffect(() => {
        console.log(answerDatas, "studentDatas");
    }, [answerDatas]);
    // const validation = z.object({
    //   username: z.email(),
    //   password: z.any(),
    // });
    // const validate = (field) => {
    //   validation.parse({ username: field });
    // };

    useEffect(() => {
        if (!localStorage.getItem("authToken")) {
            navigate("/");
        }
    });


    useEffect(() => {

        if (errorMessage) {
            toast.dismiss();
            if (errorMessage.message) toast.error(errorMessage.message);
            else if (errorMessage.errors) {
                let data = "";
                errorMessage?.errors.map((err) => {
                    data += err.message + " ";
                });
                toast.error(data);
            }
            dispatch(clearErrormsg());
        }
    }, [errorMessage]);


    return (
        <>
            <Header />
            <div
                class="page-banner-area events-pages event-details"
                style={{ paddingBottom: "50px", paddingTop: "50px" }}
            >
                <div class="container-fluid">
                    <div class="single-page-banner-content">
                        <h1>Pre-Placement Drive</h1>
                        <ul>
                            <li>Student Answers</li>
                        </ul>
                    </div>
                </div>
                <div class="page-banner-shape-1">
                    <img src={b1} alt="images" />
                </div>
                <div class="page-banner-shape-2">
                    <img src={b2} alt="images" />
                </div>
                <div class="page-banner-shape-3">
                    <img src={b3} alt="images" />
                </div>
            </div>

            <section class="pad_100">
                <div class="container">
                    <div class="row">
                        <div class="section-title left-title">
                            {/* <h2>
                Placement Officer Survey: Understanding IT Industry Expectations
                and Student Mindset
              </h2>
              <p>
                Thank you for participating in this survey. Your insights are
                invaluable in shaping the future of placements at our college.
                The information gathered will help us enhance our understanding
                of the IT industry's expectations and the factors influencing
                students success in job interviews.
              </p> */}
                        </div>

                        <div class="col-md-12">
                            <div class="form">
                                <div className="flex">
                                    <div class="col-6 pl-1" style={{ paddingRight: "15px" }}>
                                        <h2 class="sub_heading">Student Answers</h2>
                                    </div>
                                    <div class="col-6 pl-1 float-right" style={{ paddingRight: "15px" }}>
                                        <Link to={`/student_list`}
                                            name="student_list"
                                            class="btn btn-style-one btn-primary"
                                            style={{ textAlign: "right", margin: "auto" }}
                                        >
                                            Student List
                                        </Link>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div class="col-6 pl-1" style={{ paddingRight: "15px" }}>
                                        <div className="form-group">
                                            <label htmlFor="">Name: </label>
                                            <b>{answerDatas?.[0]?.user?.name}</b>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div className="form-group">
                                            <label htmlFor="">Department: </label>
                                            <b>{answerDatas?.[0]?.questions?.department?.name}</b>
                                        </div>
                                    </div>
                                </div>
                                {answerDatas &&
                                    answerDatas.map((fields) => {
                                        return (
                                            <div class="form_group">
                                                <label>
                                                    <b>{fields?.questions?.question}</b>
                                                </label>
                                                <textarea
                                                    placeholder="Your Answer"
                                                    name="answers[]"
                                                    rows="10"
                                                    cols="30"
                                                    readOnly
                                                >{fields?.answer}</textarea>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Answers;
