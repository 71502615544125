const localHost = "localhost";
export const API_BASE ='https://api.culture-catalyst.in/'
// export const API_BASE = 'http://localhost:4000/'
const localHostBaseEndpoint = `${API_BASE}api/v1`;
export const assetURL = `${API_BASE}`;
export const secretKey = (process.env.REACT_APP_SECRETKEY) ? process.env.REACT_APP_SECRETKEY : "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7";
export const getBaseEndpointUrl = () => {
  return localHostBaseEndpoint;
};

function getHostURL(hostName) {
  if (hostName.includes(localHost)) {
    return localHostBaseEndpoint;
  }
}

export const Logout = () => {
  localStorage.clear()
  return true
}
