import logo from "./logo.svg";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import "./App.css";
import "./webassets/css/style.css";
import "./webassets/css/bootstrap.min.css";
import "./webassets/css/responsive.css";
import "./webassets/css/dark.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InterviewTest from "./components/InterviewTest";
import Question from "./components/Question";
import ThankYou from "./components/ThankYou";
import StudentsList from "./components/StudentsList";
import Answers from "./components/Answers";
import HiringPartner from "./components/Hiring_Partner/HiringPartner";
import AddHiringPartner from "./components/Hiring_Partner/AddHiringPartner";
import EditHiringPartner from "./components/Hiring_Partner/EditHiringPartner";
import Quiz from "./components/Quiz";
import StudentProfile from "./components/StudentProfile";
import PrivateRoute from './PrivateRoutes';
function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* Same as */}
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/inter" element={<InterviewTest />} />
          <Route path="/question" element={<Question />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/student_list" element={<StudentsList />} />
          <Route path="/answers/:id" element={<Answers />} />
          <Route path="/hiring_partners" element={<HiringPartner />} />
          <Route path="/hiring_partner" element={<AddHiringPartner />} />
          <Route path="/edit_hiring_partners/:id" element={<EditHiringPartner />} />
          <Route path="/quiz" element={<Quiz />} />
          < Route path="/" element={<PrivateRoute />} >
            {/* { */}
            <Route path="/student_profile" element={<StudentProfile />} />
            <Route path="/*" element={<>Page Not Found</>} />
            {/* } */}
          </Route>


        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
