import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import { my_account_data } from "../../services/api";

const namespace = "User";

const initialState = {
    loading: "initial",
    errorMessage: "",
    UserDatas: null,
    UserDatasLoading: null,
};

export const myAccount = createAsyncThunk(
    `${namespace}/myAccount`,
    async ({ }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await my_account_data();
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
)

const UserSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearUserData: () => {
            return initialState;
        },
        clearData: (state) => {
            state.UserDatasLoading = "initial";
        }
    },
    extraReducers: (builder) => {
        builder.addCase(myAccount.pending, (state) => {
            state.UserDatasLoading = API_STATUS.PENDING;
        });
        builder.addCase(myAccount.fulfilled, (state, { payload }) => {
            state.UserDatasLoading = API_STATUS.FULFILLED;
            state.UserDatas = payload?.data;
            console.log(payload, "UserDataPayload");
        });
        builder.addCase(myAccount.rejected, (state, action) => {
            state.UserDatasLoading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        });
    }
});

export const { clearUserData, clearData } = UserSlice.actions;

export const UserSelector = (state) => state.User;

export default UserSlice.reducer;
