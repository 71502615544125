import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constants";
import { signIn, signInsocial, profile } from "../../services/api";
import EncryptDecrypt, { decryptdata } from "../../utils/encrypt&decrypt";
import { secretKey } from "../../services/config";

const namespace = "signInConfiguration";

const initialState = {
  loading: "initial",
  errorMessage: "",
  signInData: null,
  signInLoading: "initial",
  profileLoading: "initial",
  updateSchedulerLoading: "initial",
  profileData: null,
  count: 0,
};

export const signInScheduler = createAsyncThunk(
  `${namespace}/signInScheduler`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData");
      const { data } = await signIn(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const signInSocial = createAsyncThunk(
  `${namespace}/signInSocial`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData");
      const { data } = await signInsocial(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const userProfile = createAsyncThunk(
  `${namespace}/userProfile`,
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      console.log(formData, "formData");
      const { data } = await profile(formData);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
const signInConfigSlice = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clearSignInData: () => {
      return initialState;
    },
    clearData: (state) => {
      state.signInData = null;
      state.signInLoading = "initial"
    }
  },

  extraReducers: (builder) => {
    builder.addCase(signInScheduler.pending, (state) => {
      state.signInLoading = API_STATUS.PENDING;
    });
    builder.addCase(signInScheduler.fulfilled, (state, { payload }) => {
      state.signInLoading = API_STATUS.FULFILLED;
      let payloadData = JSON.parse(EncryptDecrypt.decryptdata(payload?.datas, secretKey))
      state.signInData = payloadData
      console.log(payloadData, "PAYSDFD");
      localStorage.setItem("authToken", payloadData?.accessToken);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("username", payloadData?.name);
    });
    builder.addCase(signInScheduler.rejected, (state, action) => {
      state.signInLoading = API_STATUS.REJECTED;
      console.log(action);
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(signInSocial.pending, (state) => {
      state.signInLoading = API_STATUS.PENDING;
    });
    builder.addCase(signInSocial.fulfilled, (state, { payload }) => {
      state.signInLoading = API_STATUS.FULFILLED;
      state.signInData = payload;
      console.log(payload);
      localStorage.setItem("authToken", payload?.accessToken);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("username", payload?.name);
    });
    builder.addCase(signInSocial.rejected, (state, action) => {
      state.signInLoading = API_STATUS.REJECTED;
      console.log(action);
      state.errorMessage = action?.payload?.data;
    });
    builder.addCase(userProfile.pending, (state) => {
      state.profileLoading = API_STATUS.PENDING;
    });
    builder.addCase(userProfile.fulfilled, (state, action) => {
      state.profileLoading = API_STATUS.FULFILLED;
      state.profileData = action?.payload?.data;
    });
    builder.addCase(userProfile.rejected, (state, action) => {
      state.profileLoading = API_STATUS.REJECTED;
      console.log(action);
      state.errorMessage = action?.payload?.data;
    });
  },
});

export const { clearSignInData, clearData } = signInConfigSlice.actions;

export const signInSelector = (state) => state.signInConfiguration;

export default signInConfigSlice.reducer;
