import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import logger from "redux-logger";
import SignInReducer from "./reducer/SignInReducer";
import SignUpReducer from "./reducer/SignUpReducer";
import PlacementReducer from "./reducer/PlacementReducer";
import HiringPartnerReducer from "./reducer/HiringPartnerReducer";
import StudentReducer from "./reducer/StudentReducer";
import UserReducer from "./reducer/UserReducer";

export const reducer = {
  signInConfiguration: SignInReducer,
  signUpConfiguration: SignUpReducer,
  placement: PlacementReducer,
  hiringPartner: HiringPartnerReducer,
  Student: StudentReducer,
  User: UserReducer
};

const store = configureStore({
  reducer,
  // middleware: [ // Because we define the middleware property here, we need to explictly add the defaults back in.
  //     ...getDefaultMiddleware(),
  // ]
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
