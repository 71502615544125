import React, { useState, useCallback, useEffect } from "react";
import {
  useNavigate,
  Link,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import debounce from "lodash/debounce";
import "../../webassets/css/style.css";
import "../../webassets/css/responsive.css";
import { useDispatch, useSelector } from "react-redux";
import s1 from "../../webassets/images/submenu-icon-1.svg";
import s3 from "../../webassets/images/submenu-icon-2.svg";
import s2 from "../../webassets/images/submenu-icon.svg";
import logo1 from "../../webassets/images/Asset 8.png";
import logo2 from "../../webassets/images/Asset 7.png";
import {
  userProfile,
  signInSelector,
  clearErrormsg,
} from "../../store/reducer/SignInReducer";
export const InnerHeader = ({ page }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = localStorage.getItem("isAuthenticated");
  const username = localStorage.getItem("username");
  const [menu, setMenu] = useState(false);
  const location = useLocation();
  const { profileData, profileLoading } = useSelector(signInSelector);

  useEffect(() => {
    let formData = {};
    dispatch(userProfile({ formData }));
  }, []);
  useEffect(() => {
    console.log(profileData, "profileData");
  }, [profileData]);

  return (
    <span>
      {/* <div class="submenu-area">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-8 col-md-9">
              <div class="submenu-left-content">
                <ul>
                  <li>
                    <img src={s1} alt="images" />
                    <a href={null}>+91 96296 55502</a>
                  </li>
                  <li>
                    <img src={s2} alt="images" />
                    <a href={null}>Coimbatore</a>
                  </li>
                  <li>
                    <img src={s3} alt="images" />
                    <a href={null}>
                      <span
                        class="__cf_email__"
                        data-cfemail="b2dad7dededdf2dcd7c1c6c79cd1dddf"
                      >
                        info@culture-catalyst.in
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-md-3">
              <div class="submenu-right-content">
                <ul>
                  <li>
                    <p>Follow Us:</p>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/" target="_blank">
                      <i class="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/" target="_blank">
                      <i class="bx bxl-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/" target="_blank">
                      <i class="bx bxl-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.pinterest.com/" target="_blank">
                      <i class="bx bxl-pinterest"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div class="navbar-area">
        {/* <div class="container"> 
                <div class="mobile-nav">  
                    <div class="logo">
                        <a href="index.html"> 
                            <img src={logo1} class="logo-light" alt="images"/>   
                        </a> 
                    </div>
                </div>  
            </div>  */}

        <div class="main-nav">
          <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
              <a class="logo" href="#">
                {/* <img src={logo1} class="logo-light" alt="images"/>    */}
                <img src={logo2} class="logo-dark" alt="images" />
              </a>

              <div
                class="collapse navbar-collapse mean-menu"
                id="navbarSupportedContent"
              >
                <ul class="navbar-nav m-auto" style={{ display: "none" }}>
                  <li class="nav-item nav-item-five">
                    <a
                      href="https://culture-catalyst.in/index.html"
                      class="nav-link"
                    >
                      Home
                    </a>
                  </li>

                  <li class="nav-item nav-item-five">
                    <a
                      href="https://culture-catalyst.in/about.html"
                      class="nav-link"
                    >
                      About Us
                    </a>
                  </li>

                  <li class="nav-item nav-item-five">
                    <a
                      href="https://culture-catalyst.in/how-it-works.html"
                      class="nav-link"
                    >
                      How it Works
                    </a>
                  </li>

                  {/* <li class="nav-item nav-item-five">
                    <Link to={`/hiring_partners`} class="nav-link">
                      Hiring Partners
                    </Link>
                  </li> */}

                  {/* <li class="nav-item nav-item-five">
                    <a href="#" class="nav-link dropdown-toggle">
                      Partners
                    </a>

                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <a href={null} class="nav-link bg-border-top">
                          Knowledge Partners
                        </a>
                      </li>

                      <li class="nav-item">
                        <a href={null} class="nav-link">
                          Hiring Partners
                        </a>
                      </li>
                    </ul>
                  </li> */}

                  <li class="nav-item nav-item-five">
                    <a href="#" class="nav-link dropdown-toggle">
                      Resources
                    </a>

                    <ul class="dropdown-menu">
                      <li class="nav-item">
                        <a href={null} class="nav-link bg-border-top">
                          Blog
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li class="nav-item">
                    <a
                      href="https://culture-catalyst.in/contact.html"
                      class="nav-link"
                    >
                      Contact Us
                    </a>
                  </li>
                  {profileData && profileData?.role_id == "1" ? (
                    <li class="nav-item">
                      <Link to={`/student_list`} class="nav-link">
                        Student List
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
                <h4>
                  {localStorage.getItem("username")
                    ? "Hello " + localStorage.getItem("username") + "!"
                    : ""}
                </h4>

                {isAuth ? (
                  page == 0 ? (
                    <>
                      <div class="others-option-vg d-flex align-items-center">
                        <div class="option-item">
                          <a
                            href="javascript:void(0);"
                            class="default-btn btn-style-one"
                            onClick={(e) => {
                              localStorage.clear();
                              // navigate("/");
                              window.location.href = "/";
                            }}
                          >
                            Logout
                          </a>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )
                ) : (
                  <div class="others-option-vg d-flex align-items-center">
                    <div class="option-item">
                      <a href="/" class="default-btn btn-style-one">
                        Login
                      </a>
                    </div>

                    <div class="option-item">
                      <a href="/signup" class="default-btn">
                        Register
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </nav>
          </div>
        </div>

        <div class="others-option-for-responsive">
          <div class="container">
            <div class="dot-menu">
              <div class="inner">
                <div class="circle circle-one"></div>
                <div class="circle circle-two"></div>
                <div class="circle circle-three"></div>
              </div>
            </div>

            <div class="container">
              <div class="option-inner">
                <div class="others-option justify-content-center d-flex align-items-center">
                  <div class="option-item">
                    <form class="search-form">
                      <input
                        class="search-input"
                        placeholder="Search Here"
                        type="text"
                      />

                      <button type="submit" class="search-button">
                        <i class="bx bx-search"></i>
                      </button>
                    </form>
                  </div>

                  <div class="option-item">
                    <a href="#" class="default-btn">
                      Get Free Estimate <i class="bx bx-plus"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </span>
  );
};
