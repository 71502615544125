import { assetURL } from "../../services/config";
import logo1 from "../../webassets/images/Asset 8.png";
import logo2 from "../../webassets/images/Asset 7.png";
import { useNavigate, Link, useLocation, useSearchParams } from "react-router-dom";

const PlainFooter = () => {
    return (
        <div class="copyright-content">
            <p>
                © <b>Culture Catalyst</b> is proudly owned by <b>Digital Garage</b>.
            </p>
        </div>
    );
};
export default PlainFooter;
